import {
    Grid,
    Menu,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    MenuItem,
    Input,
    Select,
    Button,
    Checkbox,
    FormControlLabel,
    SelectAutoComplete,
    DialogContent,
    DialogContentText,
    Typography,
    FormControl,
} from "Components/base";
import React from "react";
import { RingLoading } from "Components/RingLoading/RingLoading";
import useGameCategoriesHook from "./GameCategories.hook";
import { TextFormat, TextFormatOutlined } from "@mui/icons-material";
import { Switch, TextField } from '@mui/material';
import DragableModal from 'Components/DragableModal'

interface itemProps {
    value: any;
    label: any;
    key: any;
}

export const GameCategories = () => {
    const {
        loading,
        gameCategories,
        gameInfo,
        columns,
        apps,
        currentApp,
        onChangeApp,
        handleSelectGame,
        onSwitch,
        onEditContainerText,
        onChangeName,
        onChangeDesc,
        onSaveChange,
        onChangeThumbnail,
        onChangeSortOrder,
        onAddNewCategory,
        onDeleteGameCategories,
        handleClose,
    } = useGameCategoriesHook();

    const [openModel, setOpenModel] = React.useState(false)
    const [modalData, setModalData] = React.useState<itemProps[]>([])
    const [currentEdit, setCurrentEdit] = React.useState('')


    const mapContainerToSelected = (container: string) => {
        return container.split(',').map(g => {
            const item = gameInfo.find(gi => gi.ID == g)
            if (!item) {
                return false
            }
            return {
                value: item.ID,
                label: item.ID,
                key: item.ID
            }
        }).filter(Boolean)
    }

    const onClickOpenModal = (id:any, container: any) => {
      setCurrentEdit(id)
      if (!modalData || !modalData.length) {
          const con = container.split(',')
          const dataFiltered = gameInfo.filter((gi: any) => con.find((el: any) => gi.ID == el))
          setModalData(dataFiltered.map((item: any)=>{
            return {
                value: item.ID, 
                label: `${item.ID} - ${item.Name}`,
                key: item.ID
            }
          }))
      }
      setOpenModel(true)
    }

    const onChangeModalData = (newData: any) => {
      handleSelectGame(currentEdit, newData)
      setModalData(newData)
      setCurrentEdit('')
    }

    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    return loading ? (<RingLoading />) : (
        <div style = {{paddingLeft: 100, paddingRight: 100, paddingBottom: 100}}>
            <Grid
                container
                spacing={2}
                className="headerToolbarGrid"
                alignItems={"center"}
            >
                <Grid item xs={12} md={6} lg={3}>
                    <div className="aviron-title">Game Categories Management</div>
                </Grid>
                <Grid item xs={12} md={6} lg={9} className="buttonGroup">
                    <div style={{marginRight: '15px'}}>
                        <Select
                            value={currentApp}
                            // options={gameTypesOptions}
                            onChange={onChangeApp}
                            name="app_selection"
                            
                            fullWidth
                        >
                            {apps?.map(app => {
                                return (
                                    <MenuItem value={app.ID} key={`game-app-option-${app.ID}`}>{app.Name.toUpperCase()}</MenuItem>
                                )
                            })}
                        </Select>
                    </div>
                    
                    <Button
                        variant="contained"
                        onClick={onAddNewCategory}
                        color="secondary"
                        className="btn-primary"
                        data-cy="add-machine-btn"
                    >
                        <i className="icon-e-add" /> ADD GAME Category
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onSaveChange}
                        color="secondary"
                        className="btn-secondary"
                        data-cy="add-machine-btn"
                    >
                        <i className="icon-secondary" /> SAVE
                    </Button>
                </Grid>
            </Grid>
            <div className="table">
                <Table className="video-list-table custom-table-element" >
                    <TableHead>
                        <TableRow>
                            {columns.map((column: any) => (
                                <TableCell
                                    key={`columns-${column.id}`}
                                    width={column.width}
                                >
                                            {column.label}
                                </TableCell>
                            ))}
                            <TableCell key="action">
                                <span className="column-no">Actions</span>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        (gameCategories || []).sort((e1: any, e2: any) => e2.SortOrder - e1.SortOrder).map(el => {
                            return (
                                <TableRow role="checkbox" className="pointer cms-table-row" key={`TableRow-${currentApp}-${el.ID}`}>
                                    <TableCell>
                                        {el.ID}
                                    </TableCell>
                                    <TableCell>
                                        <TextField 
                                            fullWidth 
                                            name={el.ID}
                                            defaultValue={el.Name} 
                                            onBlur={onChangeName}
                                            inputProps={{ name: el.ID }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField 
                                            fullWidth 
                                            multiline
                                            name={el.ID}
                                            defaultValue={el.Description} 
                                            onBlur={onChangeDesc}
                                            inputProps={{ name: el.ID }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Typography component={'span'}>
                                            Multi Select
                                            <Switch
                                                name={ el.ID}
                                                value={el.textEdit}
                                                onChange={onSwitch}
                                                inputProps={{ name: el.ID }}
                                            />
                                            Text Edit
                                        </Typography>
                                       <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                    { el.textEdit ? (
                                                        <FormControl fullWidth className="form-aviron">
                                                            <TextField 
                                                                fullWidth 
                                                                multiline 
                                                                name={el.ID}
                                                                defaultValue={el.Container} 
                                                                onBlur={onEditContainerText}
                                                                inputProps={{ name: el.ID }}
                                                            />
                                                        </FormControl>
                                                    ) : (
                                                    <SelectAutoComplete
                                                        fullWidth
                                                        name={'selector-container-' + el.ID}
                                                        placeholder="Select Game"
                                                        multiple
                                                        options={gameInfo.map((item)=>{
                                                            return {
                                                                value: item.ID, // Game_1
                                                                label: `${item.ID} - ${item.Name}`, // Game so 1
                                                                key: item.ID
                                                            }
                                                        })}
                                                        onChange={handleSelectGame}
                                                        inputProps={{ name: el.ID }}
                                                        value={mapContainerToSelected(el.Container)}
                                                        isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                                    />)}
                                            </DialogContentText>
                                        </DialogContent>
                                        <div>
                                        <button
                                            aria-controls={`action-menu-${el.ID}`}
                                            aria-haspopup="true"
                                            aria-describedby={(el?.ID || '').toString()}
                                            className="actions-table pointer"
                                            // variant="contained"
                                            role="presentation"
                                            onClick={() => {
                                              onClickOpenModal(el.ID, el.Container)
                                            }}
                                            data-cy="cms-table-action-btn"
                                        >
                                            Change Order
                                        </button>
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <TextField 
                                            fullWidth 
                                            name={el.ID}
                                            defaultValue={el.Thumbnail} 
                                            onBlur={onChangeThumbnail}
                                            inputProps={{ name: el.ID }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField 
                                            fullWidth 
                                            name={el.ID}
                                            defaultValue={el.SortOrder} 
                                            onBlur={onChangeSortOrder}
                                            inputProps={{ name: el.ID }}
                                            key={currentApp + ' - ' +  el.ID}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <div className="position-relative">
                                            <button
                                                aria-controls={`action-menu-${el.ID}`}
                                                aria-haspopup="true"
                                                aria-describedby={(el?.ID || '').toString()}
                                                className="actions-table pointer"
                                                // variant="contained"
                                                role="presentation"
                                                onClick={(event) => {
                                                    setAnchorEl(
                                                        event.currentTarget
                                                    );
                                                    handleClose(
                                                        el.ID,
                                                        true
                                                    );
                                                }}
                                                data-cy="cms-table-action-btn"
                                            >
                                                <i className="icon-ic_more_vert" />
                                            </button>
                                            <Menu
                                                id={`action-menu-${el.ID}`}
                                                anchorEl={anchorEl}
                                                keepMounted
                                                //open={Boolean(anchorEl)}
                                                open={el.open}
                                                onClose={(event) => {
                                                    setAnchorEl(null);
                                                    handleClose(el.ID, false);
                                                }}
                                                data-cy="cms-table-action-menu"
                                            >
                                                <MenuItem
                                                    className="actions-table-delete pointer"
                                                    role="presentation"
                                                    onBlur={(event) =>
                                                        handleClose(
                                                            el.ID,
                                                            false
                                                        )
                                                    }
                                                    onClick={(event) => onDeleteGameCategories(el.ID)}
                                                >
                                                    Delete
                                                </MenuItem>
                                            </Menu>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })
                    }
                    </TableBody>
                </Table>
            </div>
          <DragableModal open={openModel} data={modalData} handleClose={() => {setOpenModel(false)}} setData={onChangeModalData} />
        </div>
    );
};

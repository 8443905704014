import {
  useGetAllGameTypesDataQuery,
  useAdminAddGameTypesDataMutation,
  useGetAllContentVersionQuery,
  useDeleteGameTypesDataMutation,
} from "services/graphql/";
import { useState, useMemo } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const context = {};

type gameTypesType = {
  ID: number;
  mapID?: number;
  Name: string;
  contentVersionId: string;
  oldContentVersionId: string;
  GameMode: string[];
  NumberPlayer: number;
  HasHighscore: number;
  open: boolean;
  edited: boolean;
  created?: boolean;
};

const useGameTypesHook = () => {
  const [addGameTypes] = useAdminAddGameTypesDataMutation();
  const [deleteGameType] = useDeleteGameTypesDataMutation();
  const { data, loading, refetch } = useGetAllGameTypesDataQuery({
    context,
    fetchPolicy: "no-cache",
  });
  const [gameTypes, setGameTypes] = useState([] as gameTypesType[]);
  useMemo(() => {
    if (data && data.getAllGameTypesData?.data?.length) {
      const ggt = data.getAllGameTypesData?.data
        .map((e: any) => {
          return {
            ID: e.id,
            mapID: e.id + Math.random(),
            Name: e.name,
            contentVersionId: e.contentVersionId,
            oldContentVersionId: e.contentVersionId,
            GameMode: e.gameMode,
            NumberPlayer: e.numberPlayer,
            HasHighscore: e.hasHighscore,
            open: false,
            edited: false,
          };
        })
        .sort((a: gameTypesType, b: gameTypesType) => b.ID - a.ID);
      setGameTypes(ggt);
    }
  }, [data]);

  const [availableContentVersions, setAvailableContentVersions] = useState(
    [] as any[]
  );
  const { data: contentVersions, loading: loadingCV } =
    useGetAllContentVersionQuery({ context, fetchPolicy: "no-cache" });

  const [saving, setSaving] = useState(false);

  useMemo(() => {
    if (contentVersions?.getAllContentVersion?.data?.length) {
      const dataSACV = contentVersions?.getAllContentVersion?.data.map(
        (e: any) => {
          return {
            id: e.id,
          };
        }
      );
      setAvailableContentVersions(dataSACV);
    }
  }, [contentVersions]);

  const columns = [
    {
      label: "ID",
      id: "ID",
      width: "50px",
    },
    {
      label: "Content Version",
      id: "ContentVersion",
      width: "150px",
    },
    {
      label: "Name",
      id: "Name",
      width: "200px",
    },
    {
      label: "GameMode",
      id: "GameMode",
    },
    {
      label: "NumberPlayer",
      id: "NumberPlayer",
      width: "100px",
    },
    {
      label: "HasHighscore",
      id: "HasHighscore",
      width: "100px",
    },
  ];

  const handleSelectGameMode = (el: any, newValue: any) => {
    const dataToSet = gameTypes.map((e: gameTypesType) => {
      if (e.mapID == el.mapID && e.contentVersionId == el.contentVersionId) {
        e.GameMode = newValue.map((el: any) => el.value);
        e.edited = true;
      }
      return e;
    });
    setGameTypes(dataToSet);
  };

  const addDummyGameType = () => {
    const newGtId = Math.max(...gameTypes.map((e) => e.ID)) + 1;
    const newGt: gameTypesType = {
      mapID: newGtId + Math.random(),
      ID: newGtId,
      Name: "",
      contentVersionId: availableContentVersions[0].id,
      oldContentVersionId: "",
      GameMode: ["SinglePlayer"],
      NumberPlayer: 1,
      HasHighscore: 1,
      open: false,
      edited: true,
      created: true,
    };
    setGameTypes([newGt, ...gameTypes]);
  };

  const onChangeName = (el: any, newValue: any) => {
    const dataToSet = gameTypes.map((e: gameTypesType) => {
      if (e.mapID == el.mapID && e.contentVersionId == el.contentVersionId) {
        e.Name = newValue;
        e.edited = true;
      }
      return e;
    });
    setGameTypes(dataToSet);
  };

  const onChangeNumberPlayer = (el: any, newValue: any) => {
    const dataToSet = gameTypes.map((e: gameTypesType) => {
      if (e.mapID == el.mapID && e.contentVersionId == el.contentVersionId) {
        e.NumberPlayer = +newValue;
        e.edited = true;
      }
      return e;
    });
    setGameTypes(dataToSet);
  };

  const onChangeHasHighscore = (el: any, newValue: any) => {
    const dataToSet = gameTypes.map((e: gameTypesType) => {
      if (e.mapID == el.mapID && e.contentVersionId == el.contentVersionId) {
        e.HasHighscore = +newValue;
        e.edited = true;
      }
      return e;
    });
    setGameTypes(dataToSet);
  };

  const onChangeVersionId = (el: any, newValue: any) => {
    const dataToSet = gameTypes.map((e: gameTypesType) => {
      if (
        e.mapID == el.mapID &&
        e.oldContentVersionId == el.oldContentVersionId
      ) {
        e.contentVersionId = newValue;
        e.edited = true;
      }
      return e;
    });
    setGameTypes(dataToSet);
  };

  const onChangeID = (el: any, newValue: any) => {
    const dataToSet = gameTypes.map((e: gameTypesType) => {
      if (e.mapID == el.mapID && e.contentVersionId == el.contentVersionId) {
        e.ID = +newValue;
        e.edited = true;
      }
      return e;
    });
    setGameTypes(dataToSet);
  };

  const onSaveChange = async () => {
    const dataToSave = gameTypes
      .filter((e) => e.edited)
      .map((e) => {
        return {
          id: +e.ID,
          contentVersionId: e.contentVersionId,
          oldContentVersionId: e.oldContentVersionId,
          name: e.Name,
          gameMode: e.GameMode,
          numberPlayer: +e.NumberPlayer,
          hasHighscore: +e.HasHighscore,
        };
      })
      .filter((e) => !!e.name);
    if (!dataToSave.length) {
      toast.error("Nothing changed !!!");
      return;
    }
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "this action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });
    if (!result.isConfirmed) {
      return;
    }
    if (dataToSave.length) {
      try {
        setSaving(true);
        await addGameTypes({
          variables: {
            input: { data: dataToSave },
          },
        });
        toast.success("Update Game Types success");
      } catch (e) {
        toast.error("Update Game Types ERROR");
      } finally {
        refetch();
        setSaving(false);
      }
    }
  };

  // const handleClose = (ev: any, id: any) => {
  //   const dataToSet = gameTypes.map((e: gameTypesType) => {
  //       if (e.ID == id) {
  //           e.open = false
  //       }
  //       return e
  //   })
  //   setGameTypes(dataToSet)
  // }

  const handleDelete = async (el: any) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });
    if (!result.isConfirmed) {
      return;
    }

    try {
      await deleteGameType({
        variables: {
          input: {
            id: el.ID,
            contentVersionId: el.contentVersionId,
          },
        },
      });
      toast.success("Deleted !!!");
    } catch (e) {
      toast.error("ERROR !!!");
    } finally {
      refetch();
    }
  };

  return {
    loading,
    saving,
    gameTypes,
    columns,
    availableContentVersions,
    handleSelectGameMode,
    addDummyGameType,
    onChangeName,
    onChangeNumberPlayer,
    onChangeHasHighscore,
    onSaveChange,
    onChangeVersionId,
    // handleClose,
    handleDelete,
    onChangeID,
  };
};

export default useGameTypesHook;

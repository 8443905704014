import React from "react";
import { Route, Switch } from "react-router-dom";
import { GameContentVersions } from "./GameContentVersions/GameContentVersions";
import { GameTypes } from "./GameTypes/GameTypes";
import { GameOptions } from "./GameOptions/GameOptions";
import { GameCategories } from "./GameCategories/GameCategories";
import { GameInfoScr } from "./GameInfo/GameInfo";
import { GameInfoAdd } from "./GameInfo/Add/GameInfoAdd";
import { GameInfoDetail } from "./GameInfo/Detail/GameInfoDetail";
import { CONSTANTS } from "consts";

const GameInfo: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route
                path={CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_CONTENT_VERSIONS}
                component={GameContentVersions}
                exact
            />
            <Route
                path={CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_TYPE}
                component={GameTypes}
                exact
            />
            <Route
                path={CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_CATEGORIES}
                component={GameCategories}
                exact
            />
            <Route
                path={CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_OPTION}
                component={GameOptions}
                exact
            />
            <Route
                path={CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO}
                component={GameInfoScr}
                exact
            />
            <Route
                path={CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO_ADD}
                component={GameInfoAdd}
                exact
            />
            <Route
                path={CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO_DETAIL}
                component={GameInfoDetail}
                exact
            />
            
        </Switch>
    );
};
export default GameInfo;

import { Button, FormControl, Grid, MenuItem, Select, InputLabel, TextField, Checkbox } from "Components/base";
import { CmsTableAdmin } from "Components/CmsTables/CmsTable";
import { RingLoading } from "Components/RingLoading/RingLoading";
import React from "react";
import useMainHook from "./Main.hook";
import InputDelay from "Components/base/Input/InputSearchDelay";
import AddIcon from "Components/base/Icons/AddIcon";
import ListTable from "Components/ListTable";
import { torem } from "styles/theme/toRem";
import { CATEGORY_GROUP, CATEGORY_TYPE, gameTypesDisplayName, allAppGroups } from "consts/gameShop";
import GroupCategory from "Components/GameShopProduct/GroupCategorySelect";
import ParentProductCategorySelect from "Components/GameShopProduct/ParentProductCategorySelect";
import Collapse from "../../../../Components/GameInfoV2/Collapse";
import TrashIcon from "Components/base/Icons/TrashIcon";
import { ReactComponent as SaveIcon } from "Components/base/Icons/SaveIconFilled.svg";

import style from "./Main.module.scss";

export const Main: React.FunctionComponent = () => {
    const {
        loading,
        newGt,
        items,
        onChangeGt,
        handleAddGameType,
        handleAddCategory,
        handleAddItem,
        onDeleteContent,
        onDeleteCategory,
        onSaveCategory,
    } = useMainHook();
    return (
      <div>
        <Grid
          container
          spacing={2}
          className="headerToolbarGrid"
          alignItems={"center"}
        >
          <Grid item xs={12} md={6} lg={3}>
            <div className="aviron-title">Product Management</div>
          </Grid>
        </Grid>
        <div className={style["product-main"]}>
          <Grid item xs={12} md={6} lg={9} className={style["buttonGroup"]}>
            <InputLabel
                htmlFor="outlined-age-simple1"
            >
              New Game Type
            </InputLabel>
            <Grid item xs={6} md={3} lg={2}>
              <TextField name="newGt" value={newGt} onChange={onChangeGt} />
            </Grid>
            <Button
              variant="contained"
              color="secondary"
              className="btn-primary"
              onClick={handleAddGameType}
              startIcon={<AddIcon />}
              style={{ minWidth: torem(120) }}
            >
              ADD GAME TYPE SHOP
            </Button>
          </Grid>
          {
            Object.keys(items).map((gameType: any) => {
              return ( 
                <div>
                  <div className={style["game-type"]}>
                    {(gameTypesDisplayName[gameType] || `game type ${gameType}`).toUpperCase()}
                  </div>
                  {
                    Object.values(items[gameType]).map((cateGroup: any, index: any) => {
                      return (
                        <div className={style["item-row"]} key={'content-version-' + cateGroup.categoryGroupID + '-' + index}>
                          <Collapse 
                            headerText={cateGroup.categoryGroupName.toUpperCase()} 
                            defaultOpen={false}
                            addIconProps={
                                {
                                  action: () =>
                                    handleAddCategory(gameType, cateGroup.categoryGroupID),
                                  text: "Add Category & Item",
                                }
                            }
                          >
                            {cateGroup.categories.sort((a: any, b: any) => a.categorySortOrder - b.categorySortOrder).map((category: any) => {
                              return (
                                <Grid
                                  container
                                  spacing={torem(35)}
                                  className={style["category-item"]}
                                  key={'category-item-' + category.categoryId}
                                >
                                  { category.categoryId != cateGroup.categoryGroupID ? (<Grid item xs={2} className={style["child-item"]}>
                                    <InputLabel
                                        htmlFor="outlined-age-simple1"
                                    >
                                      Category Name
                                    </InputLabel>
                                    <TextField name="categoryName" disabled={!category.edited} fullWidth value={category.categoryName} />
                                    <hr />
                                    <InputLabel
                                        htmlFor="outlined-age-simple1"
                                    >
                                      Category Order
                                    </InputLabel>
                                    <TextField name="categorySortOrder" disabled={!category.edited} fullWidth value={category.categorySortOrder} />
                                    <hr />
                                    <div onClick={() => onDeleteCategory(category)} className={style["remove-content"]}>
                                      <TrashIcon /> <span>Delete Category</span>
                                    </div>
                                    {category.edited
                                    ? 
                                    (
                                    <div onClick={() => onSaveCategory(category)} className={style["add-content"]}>
                                      <SaveIcon />
                                    </div>
                                    )
                                    : null}
                                  </Grid>) : null }
                                    {
                                      category.childs.sort((a: any, b: any) => a.sortOrder - b.sortOrder).map((child: any) => {
                                        return (
                                          <Grid item xs={3} md={4} lg={2} className={style["child-item"]}>
                                            <InputLabel
                                                htmlFor="outlined-age-simple1"
                                            >
                                              Price
                                            </InputLabel>
                                            <TextField name="price" disabled={!child.edited} fullWidth value={child.price} />
                                            <hr />
                                            <InputLabel
                                                htmlFor="outlined-age-simple1"
                                            >
                                              Name
                                            </InputLabel>
                                            <TextField name="name" disabled={!child.edited} fullWidth value={child.name} />
                                            <hr />
                                            <InputLabel
                                                htmlFor="outlined-age-simple1"
                                            >
                                              Order Number
                                            </InputLabel>
                                            <TextField name="sortOrder" disabled={!child.edited} fullWidth value={child.sortOrder} />
                                            <hr />
                                            <img src={child.imageUrl} className={style["product-img"]} />
                                            <hr />
                                            <InputLabel
                                                htmlFor="outlined-age-simple1"
                                            >
                                              App enable
                                            </InputLabel>
                                            <div className={style["app-list"]}>
                                              {allAppGroups.map((app: any) => {
                                                return (
                                                  <Checkbox value={app.id} label={app.name} checked={child.appGroupIds.includes(app.id)} />
                                                )
                                              })}
                                            </div>
                                            <hr />
                                            <Checkbox value={child.isNew} label="NEW" checked={child.isNew} />
                                            <hr />
                                            <Checkbox value={child.active} label="ACTIVE" checked={child.active} />
                                            <hr />
                                            <div onClick={() => onDeleteContent(child)} className={style["remove-content"]}>
                                              <TrashIcon />
                                            </div>
                                          </Grid>
                                        )
                                      })
                                    }
                                    <Grid item xs={1} md={2} lg={1} className="buttonGroup">
                                      <Button
                                        variant="contained"
                                        color="secondary"
                                        className="btn-primary"
                                        onClick={() => handleAddItem(gameType, cateGroup.categoryGroupID, category.categoryId)}
                                        startIcon={<AddIcon />}
                                        style={{ minWidth: torem(120) }}
                                      >
                                        ADD 
                                      </Button>
                                    </Grid>

                                </Grid>
                              )
                            })}
                          </Collapse>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
        {loading ? <RingLoading /> : null}
      </div>
    );
};

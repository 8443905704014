import * as yup from "yup";
import { urlFormatReg } from "utils/util";

export const slugValidation = (slug: any) => {
  const noSpecialCharacterReg = /^[A-Za-z0-9 \<>\_-]+$/;
  if (slug && slug !== "") {
    if (slug[0] === "/") {
      return {
        isValid: false,
        errorMessage: "Slug must not contain '/' at first letter",
      };
    } else if (slug.includes(" ")) {
      return {
        isValid: false,
        errorMessage: "Slug must not contain space letter",
      };
    } else if (!noSpecialCharacterReg.test(slug)) {
      return {
        isValid: false,
        errorMessage: "Slug must not contain specific character",
      };
    }
  }
  return {
    isValid: true,
  };
};

export const linkValidation = (link: any) => {
  // console.log({link})
  if (link && link !== "") {
    if (!urlFormatReg.test(link)) {
      return {
        isValid: false,
        errorMessage: "link must be a valid https url",
      };
    }
  }
  return {
    isValid: true,
  };
};

export const formSchema = yup.object().shape({
  id: yup.string().max(255).required("Id is a required field").trim(),
  gameName: yup.string().max(255).required("Name is a required field").trim(),
  contentVersionId: yup.string().required("App is a required field").trim(),
  gameTypeId: yup.string().required("Type is a required field").trim(),
  categoryId: yup.string().required("Category is a required field").trim(),
  shortDescription: yup
    .string()
    .required("Short description is a required field")
    .trim(),
  displayTime: yup.string().required("DisplayTime is a required field").trim(),
  thumbnail: yup.string().required("Thumbnail is a required field").trim(),
  gameDuration: yup
    .string()
    .required("Game duration is a required field")
    .trim(),
});

import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  SelectAutoComplete,
} from "Components/base";
import { RingLoading } from "Components/RingLoading/RingLoading";
import AppLabel from "Components/GameInfoV2/AppLabel";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
import React from "react";
import useAddHook from "./GameInfoDetail.hook";
import { torem } from "styles/theme/toRem";
import { ReactComponent as SaveIcon } from "Components/base/Icons/SaveIcon.svg";
import style from "./AddPost.module.scss";
import { JsonEditor } from "json-edit-react";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";

import { tags, allowUsers, statuses } from "../../consts";

export const GameInfoDetail = () => {
  const {
    formik,
    gameTypes,
    gameOptions,
    availableContentVersions,
    availableCategoriesVersions,
    apps,
    currentApp,
    onChangeApp,
    onChangeTags,
    onChangeAllowUser,
    handleSubmitForms,
    onChangeGameType,
    loading,
    onChangeCustomConfig,
    onChangeDifficulty,
    onChangeGameOption,
    onChangeGameStatus,
    onChangeContentVersion,
    onChangeGameCategory,
    availableApps,
  } = useAddHook();

  const mapToSelected = (data: string[]) => {
    console.log("mapToSelected data", data);
    return (data || []).map((g) => {
      return {
        value: g,
        label: g,
        key: g,
      };
    });
  };

  const history = useHistory();

  const onClickBack = () => {
    const path = CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO;
    history.push({ pathname: path });
  };

  return (
    <div className={style["AddPostWrapper"]}>
      <Grid
        container
        spacing={torem(16)}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item>
          <div
            className="aviron-sub-title pointer"
            onClick={() => onClickBack()}
          >
            Game Item Management &gt; Edit Game Item
          </div>
          <div className="aviron-title" role="presentation">
            &nbsp; Edit Game Item
          </div>
        </Grid>
        <Grid item className={"buttonGroup"}>
          <div style={{ marginRight: "15px" }}>
            <Select
              value={currentApp}
              // options={gameTypesOptions}
              onChange={onChangeApp}
              name="app_selection"
              fullWidth
            >
              {availableApps?.map((app: any) => {
                return (
                  <MenuItem
                    value={app.appId}
                    key={`game-app-option-${app.appId}`}
                  >
                    {app.appName.toUpperCase()}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
          <ButtonWithLoading
            variant="contained"
            loading={loading}
            type="submit"
            onClick={handleSubmitForms}
            startIcon={<SaveIcon />}
            style={{ minWidth: torem(120) }}
          >
            Save
          </ButtonWithLoading>
        </Grid>
      </Grid>
      <div className={style["addBody"]}>
        {/* {!lodash.isEmpty(formik.errors) && (
          <div style={{ paddingLeft: torem(24), marginBottom: torem(-20) }}>
            <FormHelperText>Error in Profile Form</FormHelperText>
          </div>
        )} */}
        <form>
          <div className={style["profileContent"]}>
            <FormControl
              fullWidth
              className="form-aviron"
              style={{ marginTop: torem(16) }}
            >
              <InputLabel
                // ref={inputLabel}
                htmlFor="outlined-machine-type"
                required
              >
                Content Version
              </InputLabel>
              <Select
                displayEmpty
                name="contentVersionId"
                value={formik.values.contentVersionId}
                onChange={onChangeContentVersion}
                fullWidth
                error={!!formik.errors.contentVersionId}
              >
                {availableContentVersions?.map((item: any, key: any) => {
                  return (
                    <MenuItem
                      value={item.id}
                      key={"app-content-version-" + item.id}
                    >
                      {item.id + " - " + item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              className="form-aviron"
              style={{ marginTop: torem(16) }}
            >
              <InputLabel htmlFor="outlined-machine-type">
                This game item is available on app(s):
              </InputLabel>

              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {availableApps.map((app: any) => {
                  return (
                    <AppLabel
                      key={`app-allow-${app.appId}-${app.id}`}
                      text={`${app.appId} - ${app.appName}`}
                      color="0, 188, 60"
                      colorBg="rgb(230, 248, 236)"
                    />
                  );
                })}
              </div>
            </FormControl>
            <FormControl fullWidth className="form-aviron">
              <TextField
                required
                disabled
                id="id"
                label="ID"
                fullWidth
                name="id"
                margin="normal"
                variant="outlined"
                error={formik.errors.id ? true : false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.id}
                helperText={
                  // @ts-ignore
                  formik.errors.id && formik.touched.id
                    ? formik.errors.id
                    : null
                }
              />
            </FormControl>
            <FormControl fullWidth className="form-aviron">
              <TextField
                required
                id="gameName"
                label="Name"
                placeholder="Enter Name"
                fullWidth
                name="gameName"
                margin="normal"
                variant="outlined"
                error={formik.errors.gameName ? true : false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gameName.toUpperCase()}
                helperText={
                  // @ts-ignore
                  formik.errors.gameName && formik.touched.gameName
                    ? formik.errors.gameName
                    : null
                }
              />
            </FormControl>
            <FormControl
              fullWidth
              className="form-aviron"
              style={{ marginTop: torem(16) }}
            >
              <InputLabel
                // ref={inputLabel}
                htmlFor="outlined-machine-type"
                required
              >
                Game Category
              </InputLabel>
              <Select
                displayEmpty
                name="categoryId"
                // placeholder={"Select a Game Type"}
                value={formik.values.categoryId}
                onChange={onChangeGameCategory}
                error={!!formik.errors.categoryId}
                fullWidth
              >
                {availableCategoriesVersions?.map((item: any) => {
                  return (
                    <MenuItem
                      value={item.ID}
                      key={"categoryId" + "-" + item.ID}
                    >
                      {item.ID + " - " + item.Name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={formik.errors.categoryId ? true : false}>
                {formik.errors.categoryId && formik.touched.categoryId
                  ? formik.errors.categoryId
                  : null}
              </FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              className="form-aviron"
              style={{ marginTop: torem(16) }}
            >
              <InputLabel
                // ref={inputLabel}
                htmlFor="outlined-machine-type"
                required
              >
                Game Type
              </InputLabel>
              <Select
                displayEmpty
                name="gameTypeId"
                // placeholder={"Select a Game Type"}
                value={formik.values.gameTypeId}
                onChange={onChangeGameType}
                fullWidth
                error={!!formik.errors.gameTypeId}
              >
                {gameTypes?.map((item: any) => {
                  return (
                    <MenuItem value={item.ID} key={"gameTypeId" + item.ID}>
                      {item.ID + " - " + item.Name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={formik.errors.gameTypeId ? true : false}>
                {formik.errors.gameTypeId && formik.touched.gameTypeId
                  ? formik.errors.gameTypeId
                  : null}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth className="form-aviron">
              <TextField
                required
                id="shortDescription"
                label="Short Description"
                placeholder="Enter Short Description"
                fullWidth
                name="shortDescription"
                margin="normal"
                variant="outlined"
                multiline
                rows={2}
                value={formik.values.shortDescription}
                error={
                  formik.errors.shortDescription &&
                  formik.touched.shortDescription
                    ? true
                    : false
                }
                // required={true}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.values.shortDescription}
                helperText={
                  formik.errors.shortDescription &&
                  formik.touched.shortDescription
                    ? formik.errors.shortDescription
                    : null
                }
              />
            </FormControl>
            <FormControl fullWidth className="form-aviron">
              <TextField
                id="longDescription"
                label="Long Description"
                placeholder="Enter Long Description"
                fullWidth
                name="longDescription"
                margin="normal"
                variant="outlined"
                multiline
                rows={6}
                value={formik.values.longDescription}
                error={
                  formik.errors.longDescription &&
                  formik.touched.longDescription
                    ? true
                    : false
                }
                // required={true}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                defaultValue={formik.values.longDescription}
                helperText={
                  formik.errors.longDescription &&
                  formik.touched.longDescription
                    ? formik.errors.longDescription
                    : null
                }
              />
            </FormControl>
            <FormControl fullWidth className="form-aviron">
              <TextField
                required
                id="displayTime"
                label="Display Time"
                placeholder="Enter Name"
                fullWidth
                name="displayTime"
                margin="normal"
                variant="outlined"
                error={formik.errors.displayTime ? true : false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.displayTime}
                helperText={
                  // @ts-ignore
                  formik.errors.displayTime && formik.touched.displayTime
                    ? formik.errors.displayTime
                    : null
                }
              />
            </FormControl>
            <FormControl fullWidth className="form-aviron">
              <TextField
                id="fileConfig"
                label="FileConfig"
                placeholder="Enter"
                fullWidth
                name="fileConfig"
                margin="normal"
                variant="outlined"
                error={formik.errors.fileConfig ? true : false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fileConfig}
                helperText={
                  // @ts-ignore
                  formik.errors.fileConfig && formik.touched.fileConfig
                    ? formik.errors.fileConfig
                    : null
                }
              />
            </FormControl>
            <FormControl
              fullWidth
              className={"form-aviron"}
              style={{ marginTop: torem(16) }}
            >
              <InputLabel>Custom Config</InputLabel>
              <JsonEditor
                id="customConfig"
                // colors={colorsEditorConf}
                data={formik.values.customConfig}
                // onUpdate={({newValue}) => onChangeCustomConfig(newValue)}
                setData={onChangeCustomConfig}
              />
            </FormControl>
            <FormControl
              fullWidth
              className="form-aviron"
              style={{ marginTop: torem(16) }}
            >
              <InputLabel
                // ref={inputLabel}
                htmlFor="outlined-machine-type"
              >
                Game Option
              </InputLabel>
              <Select
                displayEmpty
                name="gameOption"
                placeholder={"Select a Option"}
                value={formik.values.gameOption}
                onChange={onChangeGameOption}
                fullWidth
                error={!!formik.errors.gameOption}
              >
                {gameOptions?.map((item: any) => {
                  return (
                    <MenuItem value={item.id} key={"gameOption" + item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={formik.errors.gameOption ? true : false}>
                {formik.errors.gameOption && formik.touched.gameOption
                  ? formik.errors.gameOption
                  : null}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth className="form-aviron">
              <TextField
                required
                id="thumbnail"
                label="Thumbnail"
                placeholder="Enter Thumbnail"
                fullWidth
                name="thumbnail"
                margin="normal"
                variant="outlined"
                error={formik.errors.thumbnail ? true : false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.thumbnail}
                helperText={
                  // @ts-ignore
                  formik.errors.thumbnail && formik.touched.thumbnail
                    ? formik.errors.thumbnail
                    : null
                }
              />
            </FormControl>
            <FormControl
              fullWidth
              className={"form-aviron"}
              style={{ marginTop: torem(16) }}
            >
              {/* <JsonEditor
                name="Difficulty"
                label="Difficulty"
                value={formik.values.Difficulty}
                formik={formik}
              /> */}
              <InputLabel>Difficulty</InputLabel>
              <JsonEditor
                id="difficulty"
                // colors={colorsEditorConf}
                data={formik.values.difficulty}
                // onUpdate={({newValue}) => onChangeDifficulty(newValue)}
                setData={onChangeDifficulty}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={"form-aviron"}
              style={{ marginTop: torem(16) }}
            >
              <InputLabel
                // ref={inputLabel}
                htmlFor="outlined-machine-type"
              >
                Select Tags
              </InputLabel>
              <SelectAutoComplete
                fullWidth
                name={"selector-tags"}
                placeholder="Select Tags"
                multiple
                options={tags.map((item) => {
                  return {
                    value: item,
                    label: item,
                    key: item,
                  };
                })}
                inputProps={{ name: "selector-tags" }}
                onChange={onChangeTags}
                value={mapToSelected(formik.values.tags)}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.value == value.value
                }
              />
            </FormControl>
            <FormControl
              fullWidth
              className={"form-aviron"}
              style={{ marginTop: torem(16) }}
            >
              <InputLabel
                // ref={inputLabel}
                htmlFor="outlined-machine-type"
              >
                Select Allow Users
              </InputLabel>
              <SelectAutoComplete
                fullWidth
                name={"selector-allow-users"}
                placeholder="Select Allow Users"
                multiple
                options={allowUsers.map((item) => {
                  return {
                    value: item,
                    label: item,
                    key: item,
                  };
                })}
                inputProps={{ name: "selector-allow-users" }}
                onChange={onChangeAllowUser}
                value={mapToSelected(formik.values.allowUser)}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.value == value.value
                }
              />
            </FormControl>
            <FormControl fullWidth className="form-aviron">
              <TextField
                required
                id="gameDuration"
                label="GameDuration"
                placeholder="Enter"
                fullWidth
                type="number"
                name="gameDuration"
                margin="normal"
                variant="outlined"
                error={formik.errors.gameDuration ? true : false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gameDuration}
                helperText={
                  // @ts-ignore
                  formik.errors.gameDuration && formik.touched.gameDuration
                    ? formik.errors.gameDuration
                    : null
                }
              />
            </FormControl>
            <FormControl fullWidth className="form-aviron">
              <TextField
                id="restrictMachineType"
                label="Restrict Machine Type"
                placeholder="Enter"
                fullWidth
                name="restrictMachineType"
                margin="normal"
                variant="outlined"
                error={formik.errors.restrictMachineType ? true : false}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.restrictMachineType}
                helperText={
                  // @ts-ignore
                  formik.errors.restrictMachineType &&
                  formik.touched.restrictMachineType
                    ? formik.errors.restrictMachineType
                    : null
                }
              />
            </FormControl>
            <FormControl
              fullWidth
              className="form-aviron"
              style={{ marginTop: torem(16) }}
            >
              <InputLabel
                // ref={inputLabel}
                htmlFor="outlined-machine-type"
              >
                Game Status
              </InputLabel>
              <Select
                displayEmpty
                name="type"
                value={formik.values.status}
                onChange={onChangeGameStatus}
                fullWidth
                error={!!formik.errors.status}
              >
                {statuses?.map((item: any) => {
                  return (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error={formik.errors.status ? true : false}>
                {formik.errors.status && formik.touched.status
                  ? formik.errors.status
                  : null}
              </FormHelperText>
            </FormControl>
          </div>
        </form>
      </div>
      {loading ? <RingLoading /> : null}
    </div>
  );
};

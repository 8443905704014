import {
  Grid,
  TextField,
  InputLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
} from "Components/base";
import Collapse from "../../../../Components/GameInfoV2/Collapse";
import TrashIcon from "Components/base/Icons/TrashIcon";
import { ReactComponent as SaveIconFilled } from "Components/base/Icons/SaveIconFilled.svg";
// import { Collapse } from "@mui/material";
import React from "react";
import { RingLoading } from "Components/RingLoading/RingLoading";
import useGameContentVersionsHook from "./GameContentVersions.hook";
import style from "./GameContentVersions.module.scss";
import { torem } from "styles/theme/toRem";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";

export const GameContentVersions = () => {
  const {
    loading,
    contentVersions,
    addNewContentVersions,
    // onSaveContent,
    // onDeleteContent,
    onChangeDes,
    onChangeId,
    onRefreshGameInfoData,
    dataChange,
    openModal,
    onGetDataChange,
    onRequestSync,
    handleClose,
    onSelectItem,
    dataApply,
  } = useGameContentVersionsHook();
  const history = useHistory();

  const onClickBack = () => {
    const path = CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO;
    history.push({ pathname: path });
  };

  return loading ? (
    <RingLoading />
  ) : (
    <div className={style["all-content-version"]}>
      <Grid
        container
        spacing={2}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item xs={12} md={6} lg={3}>
          <div
            className="aviron-sub-title pointer"
            onClick={() => onClickBack()}
          >
            Game Item Management &gt; Game Content Version
          </div>
          <div className="aviron-title">Game Content Version Management</div>
        </Grid>
        <Grid item xs={12} md={6} lg={9} className="buttonGroup"></Grid>
      </Grid>
      <div className={style["content-version-list"]}>
        {contentVersions.map((el: any, index: number) => {
          return (
            <div
              className={style["version-item-row"]}
              key={"content-version-" + el.appId + "-" + index}
            >
              <Collapse
                headerText={`${el.appId} - ${el.appName}`}
                defaultOpen={false}
                syncIconProps={{
                  action: () => onGetDataChange(el.appId),
                  text: "Sync data for this app",
                }}
                refreshIconProps={{
                  action: () => onRefreshGameInfoData(el.appId),
                  text: "Apply changes for this app",
                }}
                addIconProps={{
                  action: () => addNewContentVersions(el),
                  text: "Add Content Verion",
                }}
              >
                {el.data
                  .sort((a: any, b: any) => a.priority - b.priority)
                  .map((cti: any) => {
                    return (
                      <Grid
                        container
                        spacing={torem(35)}
                        className={style["version-item"]}
                        key={"content-version-" + cti.appId + "-" + cti.id}
                      >
                        <Grid item xs={2}>
                          <InputLabel>Content Version Id</InputLabel>
                          <TextField
                            disabled={!cti.edited}
                            value={cti.contentVersionId}
                            onChange={(ev: any) => onChangeId(ev, cti)}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <InputLabel>Description</InputLabel>
                          <TextField
                            name="Description"
                            disabled={!cti.edited}
                            fullWidth
                            value={cti.description}
                            onChange={(ev: any) => onChangeDes(ev, cti)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <InputLabel>Priority</InputLabel>
                          <TextField
                            disabled
                            type="number"
                            name="Priority"
                            value={cti.priority}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          {/* <div onClick={() => onDeleteContent(cti)} className={style["remove-content"]}>
                          <TrashIcon />
                        </div>
                        {cti.edited
                        ? 
                        (
                        <div onClick={() => onSaveContent(cti)} className={style["add-content"]}>
                          <SaveIconFilled />
                        </div>
                        )
                        : null} */}
                        </Grid>
                      </Grid>
                    );
                  })}
              </Collapse>
            </div>
          );
        })}
      </div>

      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={style["alert-dialog"]}
      >
        <DialogTitle id="alert-dialog-title" className="alert-dialog-title">
          Data changes
        </DialogTitle>
        <DialogContent>
          {dataChange.gameItems?.length ? (
            <DialogContentText id="alert-dialog-description">
              <h3>Game Items Changes</h3>
              {dataChange.gameItems?.map((item: any) => {
                return (
                  <Checkbox
                    value={item.keySync}
                    checked={dataApply.gameItems?.find(
                      (el: any) => el.keySync === item.keySync
                    )}
                    key={item.keySync}
                    label={`${item.action.toUpperCase()}: ${
                      item.latestData.id
                    } - ${item.latestData.contentVersionId} - ${
                      item.latestData.gameName
                    }`}
                    onChange={() => {
                      onSelectItem("gameItems", item);
                    }}
                  />
                );
              })}
            </DialogContentText>
          ) : null}
          {dataChange.gameCategories?.length ? (
            <DialogContentText id="alert-dialog-description">
              <h3>Game Categories Changes</h3>
              {dataChange.gameCategories?.map((item: any) => {
                return (
                  <Checkbox
                    value={item.keySync}
                    checked={dataApply.gameCategories?.find(
                      (el: any) => el.keySync === item.keySync
                    )}
                    key={item.keySync}
                    label={`${item.action.toUpperCase()}: ${
                      item.latestData.id
                    } - ${item.latestData.contentVersionId} - ${
                      item.latestData.name
                    }`}
                    onChange={() => {
                      onSelectItem("gameCategories", item);
                    }}
                  />
                );
              })}
            </DialogContentText>
          ) : null}
          {dataChange.gameTypes?.length ? (
            <DialogContentText id="alert-dialog-description">
              <h3>Game Types Changes</h3>
              {dataChange.gameTypes?.map((item: any) => {
                return (
                  <Checkbox
                    value={item.keySync}
                    checked={dataApply.gameTypes?.find(
                      (el: any) => el.keySync === item.keySync
                    )}
                    key={item.keySync}
                    label={`${item.action.toUpperCase()}: ${
                      item.latestData.id
                    } - ${item.latestData.contentVersionId} - ${
                      item.latestData.name
                    }`}
                    onChange={() => {
                      onSelectItem("gameTypes", item);
                    }}
                  />
                );
              })}
            </DialogContentText>
          ) : null}
          {dataChange.gameOptions?.length ? (
            <DialogContentText id="alert-dialog-description">
              <h3>Game Options Changes</h3>
              {dataChange.gameOptions?.map((item: any) => {
                return (
                  <Checkbox
                    value={item.keySync}
                    checked={dataApply.gameOptions?.find(
                      (el: any) => el.keySync === item.keySync
                    )}
                    key={item.keySync}
                    label={`${item.action.toUpperCase()}: ${
                      item.latestData.id
                    }`}
                    onChange={() => {
                      onSelectItem("gameOptions", item);
                    }}
                  />
                );
              })}
            </DialogContentText>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              onRequestSync();
            }}
            color="primary"
            variant="contained"
          >
            Apply
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            variant="outlined"
            autoFocus
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

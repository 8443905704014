import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  MenuItem,
  Select,
  Button,
} from "Components/base";
import React from "react";
import { RingLoading } from "Components/RingLoading/RingLoading";
import useGameInfoHook from "./GameInfo.hook";
import { ReactComponent as EditIcon } from "Components/base/Icons/EditIcon.svg";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";
import { torem } from "styles/theme/toRem";
import InputDelay from "Components/base/Input/InputSearchDelay";
import PaginationControlled from "Components/PaginationControlled/PaginationControlled";
import style from "./GameInfo.module.scss";
import { tags } from "../consts";

export const GameInfoScr = () => {
  const {
    loading,
    gameInfo,
    columns,
    appContentVersions,
    currentAppContentVersion,
    onChangeApp,
    handleSearch,
    handleChangePage,
    currentPage,
    totalPage,
    filterType,
    gameTypes,
    currentGt,
    tag,
    filterTag,
  } = useGameInfoHook();

  const history = useHistory();

  const onClickAddMore = () => {
    const path = `${CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO_ADD}`;
    history.push({ pathname: path });
  };

  const onClickEdit = (id: any, contentVersionId: number) => {
    const path = `${CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO}/${id}/${contentVersionId}`;
    history.push({ pathname: path });
  };

  return loading ? (
    <RingLoading />
  ) : (
    <div style={{ paddingLeft: 100, paddingRight: 100, paddingBottom: 100 }}>
      <Grid
        container
        spacing={2}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item xs={12} md={6} lg={3}>
          <div className="aviron-title">Game Item Management</div>
        </Grid>
        <Grid item xs={12} md={6} lg={9} className="buttonGroup">
          {/* {process.env.NODE_ENV !== 'production' ? (<Button
            variant="contained"
            onClick={onRequestSync}
            color="secondary"
            className="btn-primary"
            data-cy="add-machine-btn"
            style={{ minWidth: torem(120), marginLeft: torem(16) }}
          >
            <i className="icon-up" /> SYNC GAMEINFO
          </Button>) : null } */}
          <Button
            variant="contained"
            onClick={onClickAddMore}
            color="secondary"
            className="btn-primary"
            data-cy="add-machine-btn"
            style={{ minWidth: torem(120), marginLeft: torem(16) }}
          >
            <i className="icon-e-add" /> ADD GAME
          </Button>
        </Grid>
      </Grid>
      <div className={style["Search"]}>
        <Grid item xs={6} className={style["search-input"]}>
          <Select
            value={currentAppContentVersion?.appId}
            onChange={onChangeApp}
            name="app_selection"
            fullWidth
            style={{ minWidth: torem(150) }}
          >
            {Object.values(appContentVersions)?.map((app) => {
              return (
                <MenuItem value={app.appId} key={`appOption-${app.appId}`}>
                  {app.appName.toUpperCase()}
                </MenuItem>
              );
            })}
          </Select>
          <Select
            value={currentGt}
            onChange={filterType}
            name="game_type_selection"
            displayEmpty={true}
            fullWidth
            style={{ minWidth: torem(150) }}
          >
            <MenuItem value={-1} key={`appOption-none`}>
              Select Game Types
            </MenuItem>
            {gameTypes?.map((gt) => {
              return (
                <MenuItem value={gt.ID} key={`appOption-${gt.ID}`}>
                  {gt.ID + "-" + gt.Name}
                </MenuItem>
              );
            })}
          </Select>
          <Select
            value={tag}
            onChange={filterTag}
            name="game_tag_selection"
            displayEmpty={true}
            fullWidth
            style={{ minWidth: torem(150) }}
          >
            <MenuItem value={"-1"} key={`tag-selection-none`}>
              Select Tag
            </MenuItem>
            {tags?.map((t) => {
              return (
                <MenuItem value={t} key={`tag-selection-${t}`}>
                  {t}
                </MenuItem>
              );
            })}
          </Select>
          <InputDelay
            name="input_search"
            handleSearch={handleSearch}
            props={{
              placeholder: "Search by name",
              type: "text",
              style: { minWidth: torem(250) },
            }}
          />
        </Grid>
        <Grid
          item
          xs={6}
          alignContent="flex-end"
          className={"numbersPagingWrapper"}
        >
          <PaginationControlled
            handleChangePage={handleChangePage}
            count={totalPage}
            currentPage={currentPage}
          />
        </Grid>
      </div>
      <div className="table">
        <Table className="video-list-table custom-table-element">
          <TableHead>
            <TableRow>
              {columns.map((column: any) => (
                <TableCell key={`columns-${column.id}`}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell key="action">
                <span className="column-no">Actions</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(gameInfo || []).map((el) => {
              return (
                <TableRow
                  role="checkbox"
                  className="pointer cms-table-row"
                  key={`TableRow-${el.id}-${el.contentVersionId}`}
                >
                  <TableCell>
                    <img width={64} height={64} src={el.thumbnailURL} />
                  </TableCell>
                  <TableCell>{el.id}</TableCell>
                  <TableCell>{el.gameName}</TableCell>
                  <TableCell>
                    {el.gameTypeId != null ? el.gameTypeId : "-"}
                  </TableCell>
                  <TableCell>
                    {el.contentVersionName
                      ? el.contentVersionId + " - " + el.contentVersionName
                      : "-"}
                  </TableCell>
                  <TableCell>{el.gameOption ? el.gameOption : "-"}</TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      style={{ marginRight: torem(16) }}
                      startIcon={<EditIcon />}
                      onClick={() => onClickEdit(el.id, el.contentVersionId)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

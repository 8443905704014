import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import * as React from "react";
import { useAdminGetAllIapItemsQuery } from "services";

const defaultItem = {
  appGroupIds: [
    17,
    384,
    1536
  ],
  id: "",
  name: "",
  description: "",
  price: 0,
  imageUrl: "",
  sortOrder: 9,
  clientIndex: 9,
  isDefault: false,
  active: true,
  isNew: true,
  storeId: 10,
  bgMusicId: ""
}

const defaultCate2 = {
  categoryId: -1,
  categoryName: "",
  categorySortOrder: 999,
  childs: []
}

const defaultGameTypeShop = {
    1: {
      categoryGroupID: 1,
      categoryGroupName: "background",
      categories: []
    },
    2: {
      categoryGroupID: 2,
      categoryGroupName: "character",
      categories: []
    }
  }

const useMainHook = () => {
  const { global, globalDispatch } = React.useContext(AdminAppContext);

  React.useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const [newGt, setNewGt] = React.useState(-1)
  const [items, setItems] = React.useState({} as any)

  const {
    data: allIapItems,
    loading: loadingItems,
    refetch,
  } = useAdminGetAllIapItemsQuery();

  React.useEffect(() => {
    if (
      allIapItems &&
      allIapItems?.adminGetAllIAPItems?.length
    ) {
      const buildData = {} as any
      for (const idx in allIapItems?.adminGetAllIAPItems) {
        const item = allIapItems?.adminGetAllIAPItems[idx]
        const {
          gameTypeID,
          categoryGroupID,
          categoryGroupName,
          categoryId,
          categoryName,
          categorySortOrder,
          appGroupIds,
          id,
          name,
          description,
          price,
          imageUrl,
          sortOrder,
          clientIndex,
          isDefault,
          active,
          isNew,
          storeId,
          bgMusicId,
        } = item
        
        if (!buildData[gameTypeID]) {
          buildData[gameTypeID] = {}
        }
        if (!buildData[gameTypeID][categoryGroupID]) {
          buildData[gameTypeID][categoryGroupID] = {
            categoryGroupID,
            categoryGroupName,
            categories: []
          }
        }
        const anItem = {
          appGroupIds,
          id,
          name,
          description,
          price,
          imageUrl,
          sortOrder,
          clientIndex,
          isDefault,
          active,
          isNew,
          storeId,
          bgMusicId,
        }
        
        const fIdx = (buildData[gameTypeID][categoryGroupID].categories || []).findIndex((e: any) => e.categoryId == categoryId)

        if (fIdx == -1) {
          buildData[gameTypeID][categoryGroupID].categories.push({
            categoryId,
            categoryName,
            categorySortOrder,
            childs: [anItem]
          })
        } else {
          buildData[gameTypeID][categoryGroupID]["categories"][fIdx].childs.push(anItem)
        }
      }
      setItems(buildData)
    }
      
  }, [allIapItems]);

  const onChangeGt = (ev: any) => {
    const { value } = ev.target
    setNewGt(+value)
  }
  const handleAddGameType = () => {
    const newItems = Object.assign({}, items, {[newGt]: defaultGameTypeShop})
    setItems(newItems)
  }
  const handleAddCategory = (gameType: any, categoryGroupID: any) => {
    const currentGroup = items[gameType][categoryGroupID]
    currentGroup.categories.push(defaultCate2)
    const newGTGroup = Object.assign({}, items[gameType], {[categoryGroupID]:currentGroup})
    const newItems = Object.assign({}, items, {[gameType]: newGTGroup})
    setItems(newItems)
  }
  const handleAddItem = (gameType: any, categoryGroupID: any, categoryID: any) => {
    const currentGroup = items[gameType][categoryGroupID]
    const cateIdx = currentGroup.categories.findIndex((e: any) => e.categoryId == categoryID)
    const newOrder = Math.max(...currentGroup.categories[cateIdx].childs.map((e: any) => e.sortOrder)) + 1
    const newItem = Object.assign({}, defaultItem, {id: 'new_id_' + Math.random(), sortOrder: newOrder})
    currentGroup.categories[cateIdx].childs.push(newItem)
    const newGTGroup = Object.assign({}, items[gameType], {[categoryGroupID]:currentGroup})
    const newItems = Object.assign({}, items, {[gameType]: newGTGroup})
    setItems(newItems)
  }

  const onDeleteContent = (child: any) => {}

  const onDeleteCategory = (category: any) => {}

  const onSaveCategory = (category: any) => {}

  return {
    loading: loadingItems,
    newGt,
    items,
    onChangeGt,
    handleAddGameType,
    handleAddCategory,
    handleAddItem,
    onDeleteContent,
    onDeleteCategory,
    onSaveCategory,
  };
};

export default useMainHook;

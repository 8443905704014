import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  MenuItem,
  Button,
  SelectAutoComplete,
  DialogContent,
  DialogContentText,
  TextField,
  Select,
} from "Components/base";
import React from "react";
import { RingLoading } from "Components/RingLoading/RingLoading";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
import { ReactComponent as SaveIcon } from "Components/base/Icons/SaveIcon.svg";
import TrashIcon from "Components/base/Icons/TrashIcon";
import useGameTypesHook from "./GameTypes.hook";
import { Modes } from "../consts";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";

export const GameTypes = () => {
  const {
    loading,
    saving,
    gameTypes,
    columns,
    availableContentVersions,
    handleSelectGameMode,
    addDummyGameType,
    onChangeName,
    onChangeNumberPlayer,
    onChangeHasHighscore,
    onSaveChange,
    onChangeVersionId,
    handleDelete,
    onChangeID,
  } = useGameTypesHook();

  const history = useHistory();

  const onClickBack = () => {
    const path = CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO;
    history.push({ pathname: path });
  };

  const mapGameModeToSelected = (gameModes: string[]) => {
    return gameModes.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
  };

  return loading ? (
    <RingLoading />
  ) : (
    <div style={{ paddingLeft: 100, paddingRight: 100, paddingBottom: 100 }}>
      <Grid
        container
        spacing={2}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item xs={12} md={6} lg={3}>
          <div
            className="aviron-sub-title pointer"
            onClick={() => onClickBack()}
          >
            Game Item Management &gt; Game Types
          </div>
          <div className="aviron-title">Game Types Management</div>
        </Grid>
        <Grid item xs={12} md={6} lg={9} className="buttonGroup">
          <Button
            variant="contained"
            onClick={addDummyGameType}
            color="secondary"
            className="btn-primary"
            data-cy="add-machine-btn"
          >
            <i className="icon-e-add" /> ADD GAME TYPE
          </Button>
          <ButtonWithLoading
            variant="contained"
            loading={saving}
            onClick={onSaveChange}
            startIcon={<SaveIcon />}
          >
            SAVE
          </ButtonWithLoading>
        </Grid>
      </Grid>
      <div
        style={{
          fontSize: "18px",
          textTransform: "uppercase",
          margin: "30px 0",
        }}
      >
        <b>game types are using for all apps: apk/c2/go/wr/bike ---- </b>
        <b style={{ color: "red" }}>please careful in case editing</b>
      </div>
      <div className="table">
        <Table className="video-list-table custom-table-element">
          <TableHead>
            <TableRow>
              {columns.map((column: any) => (
                <TableCell key={`columns-${column.id}`} width={column.width}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell key="action">
                <span className="column-no">Actions</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gameTypes.map((el: any, idx: number) => {
              return (
                <TableRow
                  role="checkbox"
                  className="pointer cms-table-row"
                  key={`TableRow-${idx}-${el.ID}-${el.contentVersionId}-${el.oldContentVersionId}`}
                >
                  <TableCell>
                    <TextField
                      fullWidth
                      disabled={!el.created}
                      name={el.ID.toString()}
                      defaultValue={el.ID}
                      onChange={(ev: any) => onChangeID(el, ev.target.value)}
                      type="number"
                    />
                  </TableCell>
                  <TableCell>
                    <Select
                      value={el.contentVersionId}
                      // options={gameTypesOptions}
                      onChange={(ev: any) =>
                        onChangeVersionId(el, ev.target.value)
                      }
                      name="contentVersionId"
                      fullWidth
                    >
                      {availableContentVersions?.map((cv) => {
                        return (
                          <MenuItem
                            value={cv.id}
                            key={`select-content-version-${cv.id}`}
                          >
                            {cv.id}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name={el.ID.toString()}
                      defaultValue={el.Name}
                      onChange={(ev: any) => onChangeName(el, ev.target.value)}
                    />
                  </TableCell>
                  <TableCell>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        <SelectAutoComplete
                          fullWidth
                          tag="p"
                          name={"selector-gamemode-" + el.ID}
                          placeholder="Select Mode"
                          multiple
                          options={Modes.map((item) => {
                            return {
                              value: item,
                              label: item,
                            };
                          })}
                          onChange={(name: any, value: any) =>
                            handleSelectGameMode(el, value)
                          }
                          inputProps={{ name: el.ID }}
                          value={mapGameModeToSelected(el.GameMode)}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.value === value.value
                          }
                        />
                      </DialogContentText>
                    </DialogContent>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name={el.ID.toString()}
                      defaultValue={el.NumberPlayer}
                      onChange={(ev: any) =>
                        onChangeNumberPlayer(el, ev.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      name={el.ID.toString()}
                      defaultValue={el.HasHighscore}
                      onChange={(ev: any) =>
                        onChangeHasHighscore(el, ev.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <div
                      onClick={() => handleDelete(el)}
                      style={{ cursor: "pointer" }}
                    >
                      <TrashIcon />
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

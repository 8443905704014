export const CATEGORY_GROUP = [
  {
    key: 'background',
    label: 'Background'
  },
  {
    key: 'character',
    label: 'Character'
  },
]

export const CATEGORY_TYPE = [
  {
    key: 'category1',
    label: 'Category Name 1'
  },
  {
    key: 'category2',
    label: 'Category Name 2'
  },
  {
    key: 'category3',
    label: 'Category Name 3'
  },
]

export const PRODUCT_STORE_TYPE = [
  {
    key: 10,
    label: 'AVIRON'
  },
];

export const STATUS_GAME_SHOP_PRODUCT = {
  active: 'active',
  inactive: 'inactive'
};

export const gameTypesDisplayName: {[key: number]: any} = {
  23: 'BLASTER BRIGADE',
  24: 'POWER PLAY',
  34: 'Blaze Breakers',
  21: 'BOSS BREAKER',
  33: 'Fishing Row',
  27: 'SNOW TOMORROW',
  26: 'No Tomorrow',
  35: 'SKYQUEST',
  30: 'RAG TO RICHES',
  31: 'BOSS BREAKER'
}

export const allAppGroups = [
  {
    id: 17, 
    name: 'Aviron Rower & GO'
  },
  {
    id: 2, 
    name: 'C2 Rower'
  },
  {
    id: 8, 
    name: 'C2 Bike'
  },
  {
    id: 64, 
    name: 'Water Rower'
  },
  {
    id: 384, 
    name: 'Aviron Bike & GO'
  },
  {
    id: 1536, 
    name: 'Aviron Treadmill & GO'
  }
]

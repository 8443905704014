import { useHistory } from "react-router-dom";
import {
  useAdminAddGameCategoriesVersionDataMutation,
  useAdminDeleteGameCategoriesVersionDataMutation,
  useGetAllServerAppContentVersionQuery,
  useAdminGetGameCategoriesVersionDataMutation,
  useGetAllGameInfoDataQuery,
} from "services/graphql/";
import { useState, useMemo } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const context = {};

type gameCategoriesType = {
  id: string;
  mapID?: string;
  name: string;
  contentVersionId: number;
  description: string;
  container: string;
  thumbnail: string;
  sortOrder: number;
  restrictMachineType: string;
  open: boolean;
  textEdit: boolean;
  edited: boolean;
  created?: boolean;
};

type gameInfoType = {
  ID: string;
  Name: string;
};

const useGameCategoriesHook = () => {
  const history = useHistory();
  const [addGameCategoriesVersion] =
    useAdminAddGameCategoriesVersionDataMutation();
  const [deleteGameCategoriesVersion] =
    useAdminDeleteGameCategoriesVersionDataMutation();
  const [getGameCategoriesVersions] =
    useAdminGetGameCategoriesVersionDataMutation();
  // const [getGameItemVersionData] = useAdminGetGameItemVersionDataMutation()

  const [loadingCates, setLoadingCates] = useState(false);

  const [gameInfo, setGameInfo] = useState([] as gameInfoType[]);
  const [apps, setApps] = useState([] as any[]);
  const [currentApp, setCurrentApp] = useState(1);
  const [saving, setSaving] = useState(false);

  const [allcategoriesVersions, setAllcategoriesVersions] = useState(
    [] as any[]
  );
  const [availableCategoriesVersions, setAvailableCategoriesVersions] =
    useState([] as any[]);
  const [allContentVersions, setAllContentVersions] = useState([] as any[]);

  const [availableContentVersions, setAvailableContentVersions] = useState(
    [] as any[]
  );
  const {
    data: serverAppContentVersion,
    loading,
    refetch,
  } = useGetAllServerAppContentVersionQuery({
    context,
    fetchPolicy: "no-cache",
  });

  const {
    data: giData,
    loading: giLoading,
    refetch: giRefetch,
  } = useGetAllGameInfoDataQuery({
    context,
    variables: {
      input: {
        appId: currentApp,
        ignoreData: ["ListGameOption", "ListCategoryItemData", "ListGameType"],
      },
    },
    fetchPolicy: "no-cache",
  });

  useMemo(() => {
    if (giData && giData.getAllGameInfoData?.GameList) {
      const gameList = giData.getAllGameInfoData?.GameList;
      setGameInfo(
        gameList.ListGameInfoData.map((item: any) => {
          return {
            ID: item.ID, // Game_1
            Name: item.Name, // Game so 1
          };
        })
      );
    }
  }, [giData]);

  useMemo(() => {
    if (serverAppContentVersion?.getAllServerAppContentVersion?.data?.length) {
      const dataSACV =
        serverAppContentVersion?.getAllServerAppContentVersion?.data;
      const allApps = {} as any;
      const listSacv = dataSACV.map((e: any) => {
        allApps[e.appId] = {
          Name: e.appName,
          ID: e.appId,
        };
        return {
          id: e.contentVersionId,
          name: e.description,
          appName: e.appName,
          appId: e.appId,
        };
      });
      setApps(Object.values(allApps));
      setAllContentVersions(listSacv);

      const availCVs = listSacv.filter((sac: any) => sac.appId == currentApp);
      setAvailableContentVersions(availCVs);
      const allVIds = availCVs.map((ee: any) => ee.id);

      setLoadingCates(true);
      getGameCategoriesVersions({
        variables: {
          input: {
            versionIds: allVIds,
          },
        },
        fetchPolicy: "no-cache",
      })
        .then(({ data }) => {
          const allCates = data?.adminGetGameCategoriesVersionData?.data.map(
            (e: any) => {
              return {
                id: e.id,
                mapID: e.id + Math.random(),
                name: e.name,
                oldContentVersionId: e.contentVersionId,
                contentVersionId: e.contentVersionId,
                description: e.description,
                container: e.container,
                thumbnail: e.thumbnail,
                sortOrder: e.sortOrder,
                restrictMachineType: e.restrictMachineType,
                open: false,
                textEdit: false,
                edited: false,
                created: false,
              };
            }
          );
          setAllcategoriesVersions(allCates);

          const availCates = allCates.filter((e: any) =>
            allVIds.includes(e.contentVersionId)
          );
          setAvailableCategoriesVersions(availCates);
        })
        .finally(() => {
          setLoadingCates(false);
        });
    }
  }, [serverAppContentVersion]);

  const columns = [
    {
      disablePadding: false,
      label: "ID",
      id: "ID",
      width: "100px",
    },
    {
      label: "Version Id",
      id: "contentVersionId",
      width: "150px",
    },
    {
      label: "Name/Description",
      id: "Name",
      width: "150px",
    },
    {
      label: "Container",
      id: "Container",
    },
    {
      label: "Thumbnail",
      id: "Thumbnail",
      width: "120px",
    },
    {
      label: "Sort",
      id: "SortOrder",
      width: "40px",
    },
    {
      label: "Restrict machines",
      id: "Restrict machines",
      width: "100px",
    },
  ];

  const handleSelectGame = (el: any, value: any) => {
    const dataToSet = availableCategoriesVersions.map(
      (e: gameCategoriesType) => {
        if (e.mapID == el.mapID && e.contentVersionId == el.contentVersionId) {
          e.container = value.map((el: any) => el.value).join(",");
          e.edited = true;
        }
        return e;
      }
    );
    setAvailableCategoriesVersions(dataToSet);
  };

  const onSwitch = (el: any, checked: boolean) => {
    const dataToSet = availableCategoriesVersions.map(
      (e: gameCategoriesType) => {
        if (e.mapID == el.mapID && e.contentVersionId == el.contentVersionId) {
          e.textEdit = checked;
          e.edited = true;
        }
        return e;
      }
    );
    setAvailableCategoriesVersions(dataToSet);
  };

  const onEditContainerText = (item: any, newValue: any) => {
    const dataToSet = availableCategoriesVersions.map(
      (e: gameCategoriesType) => {
        if (
          e.mapID == item.mapID &&
          e.contentVersionId == item.contentVersionId
        ) {
          e.container = newValue;
          e.edited = true;
        }
        return e;
      }
    );
    setAvailableCategoriesVersions(dataToSet);
  };

  const onChangeName = (item: any, newValue: any) => {
    const dataToSet = availableCategoriesVersions.map(
      (e: gameCategoriesType) => {
        if (
          e.mapID == item.mapID &&
          e.contentVersionId == item.contentVersionId
        ) {
          e.name = newValue.toUpperCase();
          e.edited = true;
        }
        return e;
      }
    );
    setAvailableCategoriesVersions(dataToSet);
  };

  const onChangeDesc = (item: any, newValue: any) => {
    const dataToSet = availableCategoriesVersions.map(
      (e: gameCategoriesType) => {
        if (
          e.mapID == item.mapID &&
          e.contentVersionId == item.contentVersionId
        ) {
          e.description = newValue;
          e.edited = true;
        }
        return e;
      }
    );
    setAvailableCategoriesVersions(dataToSet);
  };

  const onChangeThumbnail = (item: any, newValue: any) => {
    const dataToSet = availableCategoriesVersions.map(
      (e: gameCategoriesType) => {
        if (
          e.mapID == item.mapID &&
          e.contentVersionId == item.contentVersionId
        ) {
          e.thumbnail = newValue;
          e.edited = true;
        }
        return e;
      }
    );
    setAvailableCategoriesVersions(dataToSet);
  };

  const onChangeSortOrder = (item: any, newValue: any) => {
    const dataToSet = availableCategoriesVersions.map(
      (e: gameCategoriesType) => {
        if (
          e.mapID == item.mapID &&
          e.contentVersionId == item.contentVersionId
        ) {
          e.sortOrder = parseInt(newValue);
          e.edited = true;
        }
        return e;
      }
    );
    setAvailableCategoriesVersions(dataToSet);
  };

  const onChangeVersionId = (item: any, newValue: any) => {
    const clonedCates = [...availableCategoriesVersions];
    const fIdx = clonedCates.findIndex(
      (e: gameCategoriesType) =>
        e.mapID == item.mapID && e.contentVersionId == item.contentVersionId
    );
    if (fIdx != -1) {
      clonedCates[fIdx].contentVersionId = newValue;
      clonedCates[fIdx].edited = true;
    }
    setAvailableCategoriesVersions(clonedCates);
  };

  const onAddNewCategory = () => {
    const newSO =
      Math.max(...availableCategoriesVersions.map((e) => e.sortOrder)) + 1;
    const maxId =
      Math.max(
        ...availableCategoriesVersions.map((e) => {
          const matches = e.id.match(/\d+/);
          if (matches) {
            return +matches[0];
          }
          // const toNum = (e?.ID || '0').match(/\d+/)[0]
          return 0;
        })
      ) + 1;
    const newCate: gameCategoriesType = {
      id: "Cate_" + maxId,
      mapID: "Cate_" + maxId + Math.random(),
      contentVersionId: availableContentVersions[0].id,
      name: "",
      description: "",
      container: "",
      thumbnail: "",
      sortOrder: newSO,
      restrictMachineType: "",
      open: false,
      textEdit: false,
      edited: false,
      created: true,
    };
    setAvailableCategoriesVersions([newCate, ...availableCategoriesVersions]);
  };

  const onSaveChange = async () => {
    const dataToSave = availableCategoriesVersions
      .filter((e) => e.edited)
      .map((e) => {
        return {
          id: e.id,
          contentVersionId: e.contentVersionId,
          oldContentVersionId: e.oldContentVersionId,
          name: e.name,
          description: e.description,
          container: e.container,
          thumbnail: e.thumbnail,
          sortOrder: e.sortOrder,
          restrictMachineType: e.restrictMachineType,
        };
      });
    if (dataToSave.length) {
      setSaving(true);
      const { data, errors } = await addGameCategoriesVersion({
        variables: {
          input: { data: dataToSave },
        },
      });
      if (errors && errors?.length) {
        toast.error("ERROR !!!");
      } else {
        toast.success("Success !!!");
      }
      setSaving(false);
      window.location.reload();
    }
  };

  const onDeleteGameCategories = async (item: any) => {
    const dataToSet = availableCategoriesVersions.map(
      (e: gameCategoriesType) => {
        if (
          e.mapID == item.mapID &&
          e.contentVersionId == item.contentVersionId
        ) {
          e.open = false;
        }
        return e;
      }
    );
    setAvailableCategoriesVersions(dataToSet);
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });
    if (!result.isConfirmed) {
      return;
    }
    try {
      setSaving(true);
      await deleteGameCategoriesVersion({
        variables: {
          input: {
            contentVersionId: item.contentVersionId,
            id: item.id,
          },
        },
      });
      const dataToSet = availableCategoriesVersions.filter(
        (e: gameCategoriesType) => e.id != item.id
      );
      setAvailableCategoriesVersions(dataToSet);
      toast.success("Deleted !!!");
    } catch (e) {
      toast.error("ERROR !!!");
    } finally {
      setSaving(false);
      window.location.reload();
    }
  };

  const handleClose = (item: any, open: boolean) => {
    const dataToSet = availableCategoriesVersions.map(
      (e: gameCategoriesType) => {
        if (e.id == item.id && e.contentVersionId == item.contentVersionId) {
          e.open = open;
        }
        return e;
      }
    );
    setAvailableCategoriesVersions(dataToSet);
  };

  const onChangeId = (item: any, newValue: any) => {
    const dataToSet = availableCategoriesVersions.map(
      (e: gameCategoriesType) => {
        if (
          e.mapID == item.mapID &&
          e.contentVersionId == item.contentVersionId
        ) {
          e.id = newValue;
          e.edited = true;
        }
        return e;
      }
    );
    setAvailableCategoriesVersions(dataToSet);
  };

  const onChangeApp = (event: any) => {
    const { value } = event.target;
    setCurrentApp(+value);

    const availCVs = allContentVersions.filter(
      (sac: any) => sac.appId == +value
    );
    setAvailableContentVersions(availCVs);
    const allVIds = availCVs.map((ee: any) => ee.id);

    setLoadingCates(true);
    getGameCategoriesVersions({
      variables: {
        input: {
          versionIds: allVIds,
        },
      },
      fetchPolicy: "no-cache",
    })
      .then(({ data }) => {
        const allCates = data?.adminGetGameCategoriesVersionData?.data.map(
          (e: any) => {
            return {
              id: e.id,
              mapID: e.id + Math.random(),
              name: e.name,
              contentVersionId: e.contentVersionId,
              oldContentVersionId: e.contentVersionId,
              description: e.description,
              container: e.container,
              thumbnail: e.thumbnail,
              sortOrder: e.sortOrder,
              restrictMachineType: e.restrictMachineType,
              open: false,
              textEdit: false,
              edited: false,
            };
          }
        );
        setAllcategoriesVersions(allCates);

        const availCates = allCates.filter((e: any) =>
          allVIds.includes(e.contentVersionId)
        );
        setAvailableCategoriesVersions(availCates);
      })
      .finally(() => {
        setLoadingCates(false);
      });

    giRefetch({
      input: {
        appId: +value,
        ignoreData: ["ListGameOption", "ListCategoryItemData", "ListGameType"],
      },
    });
  };

  const onChangeRestrictMachineType = (item: any, newValue: any) => {
    const dataToSet = availableCategoriesVersions.map(
      (e: gameCategoriesType) => {
        if (
          e.mapID == item.mapID &&
          e.contentVersionId == item.contentVersionId
        ) {
          e.restrictMachineType = newValue;
          e.edited = true;
        }
        return e;
      }
    );
    setAvailableCategoriesVersions(dataToSet);
  };

  return {
    loading: loading || loadingCates || giLoading,
    saving,
    columns,
    apps,
    currentApp,
    // contentVersion,
    availableContentVersions,
    allContentVersions,
    availableCategoriesVersions,
    gameInfo,
    onChangeApp,
    handleSelectGame,
    onSwitch,
    onEditContainerText,
    onChangeName,
    onChangeDesc,
    onSaveChange,
    onChangeThumbnail,
    onChangeSortOrder,
    onAddNewCategory,
    onDeleteGameCategories,
    handleClose,
    // onChangeContentVersion,
    onChangeVersionId,
    onChangeId,
    onChangeRestrictMachineType,
  };
};

export default useGameCategoriesHook;

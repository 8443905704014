import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from "Components/base";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
import React from "react";
import useAddHook from "./Detail.hook";
import { torem } from "styles/theme/toRem";
import { ReactComponent as SaveIcon } from "Components/base/Icons/SaveIcon.svg";
import { ReactComponent as ExportIcon } from "Components/base/Icons/ExportIcon.svg";
import style from "./AddPost.module.scss";
import SPItem from "../../../../Components/StructuredProgram/Item";
import { RingLoading } from "Components/RingLoading/RingLoading";
import { DatePicker } from "Components/DatePicker/DatePicker";
import AddIcon from "Components/base/Icons/AddIcon";
import TrashIcon from "Components/base/Icons/TrashIcon";
import { ReactComponent as EditIcon }  from "Components/base/Icons/EditIcon.svg";
import { ImageUpload } from "Components/ImageUpload/ImageUpload";
import {difficulties, spLength, spIntensities} from '../consts'
import { ReactComponent as DuplicateIcon } from "Components/base/Icons/DuplicateIcon.svg";

type ItemProps = {
key: number;
name: string;
gameId: string;
gameType: number;
description: string;
requirements: any;
dueDate: number;
thumbnail: string;
thumbnailUrl: string;
}


const StructuredProgramAdd = () => {
  const {
      loading,
      setBack,
      formik,
        conditionFormik,
      handleSubmitForms,
      addMoreProgram,
      gameInfos,
      requirements,
      onAddProgram,
      onDelProgram,
      onEditProgram,
      state,
      handleClose,
      changeImage,
      changeStartTime,
      changeEndTime,
      handleExportCSV,
      onDupProgram,
  } = useAddHook();

  const onChangeAProgram = (el: any, name: any, value: any) => {
    el[name] = value
    onAddProgram(el)
  }

  const onChangeRequirement = (el: any, idx: any, value: any) => {
    if (el.requirements[value]) {
      return
    }
    const reqIdx = Object.keys(el.requirements)[idx]
    if (reqIdx && Object.hasOwn(el.requirements,reqIdx)) {
      el.requirements[value] = el.requirements[reqIdx]
      delete el.requirements[reqIdx]
    } else {
      el.requirements[value] = 0
    }
    const updateItem = Object.assign({}, el, {requirements: el.requirements})
    onAddProgram(updateItem)
  }

  const onChangeRequirementValue = (el: any, type: any, value: any) => {
    el.requirements[type] = +value
    onAddProgram(el)
  }

  return (
    <div className={style["AddSPWrapper"]}>
      <Grid
        container
        spacing={torem(16)}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item>
          <div className="aviron-sub-title pointer" onClick={setBack}>
            Structured Program Management &gt; Add Structured Program
          </div>
          <div className="aviron-title" role="presentation">
            &nbsp; Add Structured Program
          </div>
        </Grid>
        <Grid item className={"buttonGroup"}>
            <ButtonWithLoading
              variant="outlined"
              onClick={handleExportCSV}
              startIcon={<ExportIcon />}
              style={{ minWidth: torem(120), marginRight: torem(16) }}
            >
              Export CSV
            </ButtonWithLoading>
          <ButtonWithLoading
            variant="contained"
            loading={loading}
            type="submit"
            onClick={handleSubmitForms}
            startIcon={<SaveIcon />}
            style={{ minWidth: torem(120) }}
          >
            Save
          </ButtonWithLoading>
        </Grid>
      </Grid>
      <div className={style["addBody"]}>
        {/* {!lodash.isEmpty(formik.errors) && (
          <div style={{ paddingLeft: torem(24), marginBottom: torem(-20) }}>
            <FormHelperText>Check error in form</FormHelperText>
          </div>
        )} */}
        <div className={style["main-sp"]}>
          <Grid
            container
            spacing={torem(16)}
            className="inputSelectFormGroup"
          >
              {/* <Grid item xs={6}>
                <img src={formik.values.thumbnailUrl} alt="Icon" style={{marginBottom: '15px'}} />
              </Grid> */}
            <Grid container xs={2} className={style["sp-left-detail"]}>
              <Grid item>
                <FormControl fullWidth className="form-aviron">
                  <ImageUpload
                    label="Game Thumbnail"
                    required={true}
                    //@ts-ignore
                    thumbValue={formik.values.thumbnailUrl}
                    onChangeValue={formik.handleChange}
                    setFieldValue={formik.setFieldValue}
                    nameFieldForm="iconGray"
                    isHideUploadFile
                    defaultValue={formik.values.thumbnailUrl}
                    multi={false}
                    changeImage={changeImage}
                  />
                  <div style={{ marginBottom: '20px' }}>
                    <FormHelperText>
                      Image use same path for Production and Development. Please check carefully the image name, it will replace current once if exists.<br/>
                      Please use SP id as image name !!!
                    </FormHelperText>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container xs={10} spacing={torem(16)} className={style["sp-right-detail"]}>
              <Grid container xs={12}  spacing={torem(16)}>
                <Grid item xs={3}>
                  <FormControl fullWidth className="form-aviron">
                    <TextField
                      required
                      id="spId"
                      label="ID"
                      placeholder="Enter SP Id"
                      fullWidth
                      name="spId"
                      margin="normal"
                      variant="outlined"
                      error={formik.errors.spId ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.spId}
                      helperText={
                        // @ts-ignore
                        formik.errors.spId && formik.touched.spId
                          ? formik.errors.spId
                          : null
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth className="form-aviron">
                    <TextField
                      required
                      id="title"
                      label="Title"
                      placeholder="Enter title"
                      fullWidth
                      name="title"
                      margin="normal"
                      variant="outlined"
                      error={formik.errors.title ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      helperText={
                        // @ts-ignore
                        formik.errors.title && formik.touched.title
                          ? formik.errors.title
                          : null
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth className="form-aviron">
                    <TextField
                      required
                      id="description"
                      label="Description"
                      placeholder="Enter description"
                      fullWidth
                      name="description"
                      margin="normal"
                      variant="outlined"
                      error={formik.errors.description ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      helperText={
                        // @ts-ignore
                        formik.errors.description && formik.touched.description
                          ? formik.errors.description
                          : null
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={torem(16)}>
                <Grid item xs={6}>
                  <FormControl fullWidth className="form-aviron">
                    <TextField
                      required
                      multiline
                      id="longDesc"
                      label="Long Description"
                      placeholder="Enter longDesc"
                      fullWidth
                      name="longDesc"
                      margin="normal"
                      variant="outlined"
                      error={formik.errors.longDesc ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.longDesc}
                      helperText={
                        // @ts-ignore
                        formik.errors.longDesc && formik.touched.longDesc
                          ? formik.errors.longDesc
                          : null
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container xs={12}  spacing={torem(16)}>
                <Grid item xs={2}>
                  <FormControl
                    fullWidth
                    className="form-aviron"
                    style={{ marginTop: torem(16) }}
                  >
                    <InputLabel
                      // ref={inputLabel}
                      htmlFor="outlined-machine-type"
                      required
                    >
                      Difficulty
                    </InputLabel>
                    <Select
                      name="difficulty"
                      value={formik.values?.difficulty}
                      onChange={formik.handleChange}
                      fullWidth
                    >
                      {difficulties.map(e => (<MenuItem value={e} key={'difficulty-' + e}>{e}</MenuItem>))}
                        
                    </Select>
                    <FormHelperText error={formik.errors.difficulty ? true : false}>
                      {formik.errors.difficulty && formik.touched.difficulty
                        ? formik.errors.difficulty
                        : null}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl
                    fullWidth
                    className="form-aviron"
                    style={{ marginTop: torem(16) }}
                  >
                    <InputLabel
                      // ref={inputLabel}
                      htmlFor="outlined-machine-type"
                      required
                    >
                      Length
                    </InputLabel>
                    <Select
                      name="length"
                      value={formik.values?.length}
                      onChange={formik.handleChange}
                      fullWidth
                    >
                      {spLength.map(e => (<MenuItem value={e} key={'length-' + e}>{e}</MenuItem>))}
                        
                    </Select>
                    <FormHelperText error={formik.errors.length ? true : false}>
                      {formik.errors.length && formik.touched.length
                        ? formik.errors.length
                        : null}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl
                    fullWidth
                    className="form-aviron"
                    style={{ marginTop: torem(16) }}
                  >
                    <InputLabel
                      // ref={inputLabel}
                      htmlFor="outlined-machine-type"
                      required
                    >
                      Intensity
                    </InputLabel>
                    <Select
                      name="intensity"
                      value={formik.values?.intensity}
                      onChange={formik.handleChange}
                      fullWidth
                    >
                      {spIntensities.map(e => (<MenuItem value={e} key={'intensity-' + e}>{e}</MenuItem>))}
                        
                    </Select>
                    <FormHelperText error={formik.errors.intensity ? true : false}>
                      {formik.errors.intensity && formik.touched.intensity
                        ? formik.errors.intensity
                        : null}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container xs={12}  spacing={torem(16)}>
                <Grid item xs={3}>
                  <FormControl fullWidth className="form-aviron select">
                    <DatePicker
                      label="Start Time"
                      value={formik.values.startTime}
                      name="startTime"
                      onChange={changeStartTime}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth className="form-aviron select">
                    <DatePicker
                      label="End Time"
                      value={formik.values.endTime}
                      name="endTime"
                      onChange={changeEndTime}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container xs={12}  spacing={torem(16)}>
                <Grid item xs={6}>
                  <FormControl fullWidth className="form-aviron">
                    <TextField
                      id="tag"
                      label="Tag"
                      placeholder="Enter tag"
                      fullWidth
                      name="tag"
                      margin="normal"
                      variant="outlined"
                      error={formik.errors.tag ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.tag}
                      helperText={
                        // @ts-ignore
                        formik.errors.tag && formik.touched.tag
                          ? formik.errors.tag
                          : null
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth className="form-aviron">
                    <Checkbox
                      checked={formik.values.hasStreaming}
                      name="hasStreaming"
                      value={formik.values.hasStreaming}
                      label="Has Streaming"
                      inputProps={{
                          "aria-label":
                              "primary checkbox",
                      }}
                      onChange={formik.handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container xs={12}  spacing={torem(16)}>
                <Grid item xs={6}>
                  <FormControl fullWidth className="form-aviron">
                    <TextField
                      multiline
                      id="note"
                      label="Note"
                      placeholder="Enter note"
                      fullWidth
                      name="note"
                      margin="normal"
                      variant="outlined"
                      error={formik.errors.note ? true : false}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.note}
                      helperText={
                        // @ts-ignore
                        formik.errors.note && formik.touched.note
                          ? formik.errors.note
                          : null
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <FormControl fullWidth className="form-aviron">
                    <Checkbox
                        checked={formik.values.isSuggest}
                        name="isSuggest"
                        value={formik.values.isSuggest}
                        label="Suggest on Machine's Home Screen"
                        inputProps={{
                            "aria-label":
                                "primary checkbox",
                        }}
                        onChange={formik.handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className={style["programs"]}>
          <div style={{ margin: '10px' }}>
            <FormHelperText>
              !!! Becarefully when delete a workout of a SP was published. It can be corrupt the system !!! 
            </FormHelperText>
          </div>
          <div className={style["simple-program"] + ' ' + style["header"]}>
            <div className={style["program-key"]}><b>#</b></div>
            <div className={style["program-desc"]}><b>Thumb</b></div>
            <div className={style["program-name"]}><b>Name</b></div>
            <div className={style["program-id"]}><b>Game_ID</b></div>
            <div className={style["program-due"]}><b>Game Type</b></div>
            <div className={style["program-due"]}><b>Due Date</b></div>
            <div className={style["program-cond"]}><b>Condition</b></div>
            <div className={style["program-desc"]}><b>Instruction</b></div>
            <div className={style["program-action"]}><b>Action</b></div>
          </div>
          {formik.values.items.sort((a: any, b: any) => a.key - b.key).map((el: any) => {
            return (
                <div className={style["simple-program"]} key={'sp-item-' + formik.values.spId + '-' + el.mapKey}>
                <div className={style["program-key"]}>
                  <TextField
                    id="key"
                    placeholder="99"
                    fullWidth
                    name="key"
                    margin="normal"
                    variant="outlined"
                    error={!el.key}
                    onChange={(ev: any) => onChangeAProgram(el, 'key', +ev.target.value)}
                    value={el.key}
                    type="number"
                  />
                </div>
                <div className={style["program-desc"]}>
                  <img src={el.thumbnailUrl} alt="Icon" style={{width: '80px'}} />
                  <TextField
                    id="thumbnail"
                    placeholder="Enter thumbnail"
                    fullWidth
                    name="thumbnail"
                    margin="normal"
                    variant="outlined"
                    error={!el.thumbnail}
                    onChange={(ev: any) => onChangeAProgram(el, 'thumbnail', ev.target.value)}
                    value={el.thumbnail}
                  />
                </div>
                <div className={style["program-name"]}>
                  <TextField
                    id="name"
                    placeholder="Enter name"
                    fullWidth
                    name="name"
                    margin="normal"
                    variant="outlined"
                    error={!el.name}
                    onChange={(ev: any) => onChangeAProgram(el, 'name', ev.target.value.toUpperCase())}
                    value={el.name}
                  />
                </div>
                <div className={style["program-id"]}>
                  <TextField
                    id="gameId"
                    placeholder="Game_0"
                    fullWidth
                    name="gameId"
                    margin="normal"
                    variant="outlined"
                    error={!el.gameId}
                    onChange={(ev: any) => onChangeAProgram(el, 'gameId', ev.target.value)}
                    value={el.gameId}
                  />
                </div>
                <div className={style["program-due"]}>
                  <TextField
                    id="gameType"
                    placeholder="Game Type"
                    fullWidth
                    name="gameType"
                    margin="normal"
                    variant="outlined"
                    error={!el.gameType}
                    onChange={(ev: any) => onChangeAProgram(el, 'gameType', +ev.target.value)}
                    value={el.gameType}
                    type="number"
                  />
                </div>
                <div className={style["program-due"]}>
                  <TextField
                    id="dueDate"
                    placeholder="Due Date"
                    fullWidth
                    name="dueDate"
                    margin="normal"
                    variant="outlined"
                    error={!el.dueDate}
                    onChange={(ev: any) => onChangeAProgram(el, 'dueDate', +ev.target.value)}
                    value={el.dueDate}
                    type="number"
                  />
                </div>
                <div className={style["program-cond"]}>
                  {
                    [0,1,2].map((i: number) => {
                      return (
                        <div style={{display: "flex", alignItems: 'center'}} key={'select-item-'+el.mapKey+ '-' + i}>
                          <Select
                            style={{minWidth: "120px", marginTop: '2px'}}
                            name={'select-requirement' + (el.key +  i)}
                            value={Object.keys(el.requirements)[i] || null}
                            onChange={(ev: any) => onChangeRequirement(el, i, ev.target.value)}
                            fullWidth
                            displayEmpty
                          >
                            {requirements.map((e: any) => (<MenuItem key={'select-box-requirement' + (e.type + 1)} value={e.type}>{e.name}</MenuItem>))}
                          </Select>
                          &nbsp;&nbsp;
                            <TextField
                              style={{marginTop: '8px'}}
                              id={'condition-value-'+el.mapKey+i}
                              fullWidth
                              name={'condition-value-'+el.mapKey+i}
                              margin="normal"
                              variant="outlined"
                              onChange={(ev: any) => onChangeRequirementValue(el, Object.keys(el.requirements)[i] || requirements[i]?.type, ev.target.value)}
                              value={Object.keys(el.requirements)[i] ? el.requirements[Object.keys(el.requirements)[i]] : ''}
                              type="number"
                            />
                        </div>
                      )
                    })
                  }
                </div>
                <div className={style["program-desc"]}>
                  <TextField
                    multiline
                    id="description"
                    placeholder="Enter description"
                    fullWidth
                    name="description"
                    margin="normal"
                    variant="outlined"
                    error={!el.description}
                    onChange={(ev: any) => onChangeAProgram(el, 'description', ev.target.value)}
                    value={el.description}
                  />
                </div>
                <div className={style["program-action"]}>
                  <div className={style["edit"]} onClick={() => onEditProgram(el)} title="EDIT"><EditIcon/></div>
                  <div onClick={() => onDelProgram(el)} title="DELETE"><TrashIcon/></div>
                  <div className={style["duplicate"]} onClick={() => onDupProgram(el)} title="DUPLICATE"><DuplicateIcon/></div>
                </div>
              </div>
            )
          })}
          <div className={ style["addGroupConditionButton"] }>
            <div className={style["addButtonText"]} onClick={addMoreProgram}>
              <AddIcon />
              Add Program
            </div>  
          </div>  
        </div>
      </div>
      {loading ? <RingLoading /> : null}
      {<SPItem item={state.item} gameInfos={gameInfos} requirements={requirements} onPushOut={onAddProgram} onDelProgram={onDelProgram} open={state.open} handleClose={handleClose} />}
    </div>
  );
};

export default StructuredProgramAdd;

import React from "react";
import style from "./AppLabel.module.scss";

interface AppLabel {
  text: string,
  color?:string, //Numbers of rgba color, not include parentheses
  colorBg?:string
}
const AppLabel = (props: AppLabel) => {
  const { text, color, colorBg } = props;
  return (
    <div className={style["AppLabel"]}
        style={{
          color: `rgb(${color})`,
          border: `1px solid rgba(${color}, 0.7)`,
          background: `${colorBg}`,
        }}
      >
        {text}
    </div>
  );
};

export default AppLabel;

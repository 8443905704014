import {
  Grid,
  Menu,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  MenuItem,
  Button,
  TextField,
} from "Components/base";
import React from "react";
import { RingLoading } from "Components/RingLoading/RingLoading";
import useGameOptionsHook from "./GameOptions.hook";
import { JsonEditor } from "json-edit-react";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "consts";
import TrashIcon from "Components/base/Icons/TrashIcon";

export const GameOptions = () => {
  const {
    loading,
    gameOptions,
    columns,
    onAddNewOption,
    onSaveChange,
    onChangeOption1,
    onChangeOption2,
    // handleChangeId,
    onDeleteOption,
  } = useGameOptionsHook();

  const history = useHistory();

  const onClickBack = () => {
    const path = CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO;
    history.push({ pathname: path });
  };

  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  return loading ? (
    <RingLoading />
  ) : (
    <div style={{ paddingLeft: 100, paddingRight: 100, paddingBottom: 100 }}>
      <Grid
        container
        spacing={2}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item xs={12} md={6} lg={3}>
          <div
            className="aviron-sub-title pointer"
            onClick={() => onClickBack()}
          >
            Game Item Management &gt; Game Options
          </div>
          <div className="aviron-title">Game Options Management</div>
        </Grid>
        <Grid item xs={12} md={6} lg={9} className="buttonGroup">
          <Button
            variant="contained"
            onClick={onAddNewOption}
            color="secondary"
            className="btn-primary"
            data-cy="add-machine-btn"
          >
            <i className="icon-e-add" /> ADD GAME Option
          </Button>
          <Button
            variant="contained"
            onClick={onSaveChange}
            color="secondary"
            className="btn-secondary"
            data-cy="add-machine-btn"
          >
            <i className="icon-secondary" /> SAVE
          </Button>
        </Grid>
      </Grid>
      <div
        style={{
          fontSize: "18px",
          textTransform: "uppercase",
          margin: "30px 0",
        }}
      >
        <b>game options are using for all apps: apk/c2/go/wr/bike ---- </b>
        <b style={{ color: "red" }}>please careful in case editing</b>
      </div>
      <div className="table">
        <Table className="video-list-table custom-table-element">
          <TableHead>
            <TableRow>
              {columns.map((column: any) => (
                <TableCell
                  key={`columns-${column.id}`}
                  padding={column.disablePadding ? "none" : "normal"}
                  width={column.width}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell key="action">
                <span className="column-no">Actions</span>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {gameOptions.map((el, idx) => {
              return (
                <TableRow
                  role="checkbox"
                  className="pointer cms-table-row"
                  key={`TableRow-${el.id}`}
                >
                  <TableCell>
                    <TextField
                      required
                      id="OptId"
                      placeholder="Enter Id"
                      fullWidth
                      name={el.idx.toString()}
                      margin="normal"
                      variant="outlined"
                      // onChange={handleChangeId}
                      disabled
                      value={el.id}
                    />
                  </TableCell>
                  <TableCell>
                    <JsonEditor
                      data={JSON.parse(el.option1 || "{}")}
                      // onUpdate={({newValue, path}) => onChangeOption1(el.idx, path, newValue)}
                      setData={(newData) => onChangeOption1(el.idx, newData)}
                    />
                  </TableCell>
                  <TableCell>
                    <JsonEditor
                      data={JSON.parse(el.option2 || "{}")}
                      setData={(newData) => onChangeOption2(el.idx, newData)}
                    />
                  </TableCell>
                  <TableCell>
                    <div className="position-relative">
                      <div
                        onClick={() => onDeleteOption(el.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <TrashIcon />
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

import {
  useGetAllGameTypesDataQuery,
  useAdminSyncGameDataMutation,
  useGetAllServerAppContentVersionQuery,
  useAdminGetGameItemVersionDataMutation,
} from "services/graphql/";
import { useState, useMemo } from "react";
import { toast } from "react-toastify";

const ROWS_PER_PAGE = 20;

const context = {};

type gameInfoType = {
  id: string;
  contentVersionId: number;
  contentVersionName?: string;
  gameName: string;
  displayTime: string;
  fileConfig: string;
  customConfig: object;
  gameOption: string;
  thumbnail: string;
  thumbnailURL: string;
  difficulty: object;
  tags: any;
  gameDuration: number;
  allowUser: string;
  restrictMachineType: string;
  status: string;
  gameTypeId: number;
  categoryId: string;
  open: boolean;
  textEdit: boolean;
  edited: boolean;
};
type gameTypesType = {
  ID: number;
  Name: string;
};

type serverAppContentVersionType = {
  serverVersionId: number;
  appId: number;
  contentVersionIds: number[];
  versionNumbers: string[];
  appName: string;
  description: string;
  appGroupId: number;
};

const columns = [
  {
    label: "Thumbnail",
    id: "Thumbnail",
    width: "15%",
  },
  {
    disablePadding: false,
    label: "ID",
    id: "ID",
    width: "15%",
  },
  {
    label: "Name",
    id: "Name",
    width: "25%",
  },
  {
    label: "Game Type",
    id: "GameTypeID",
    width: "10%",
  },
  {
    label: "Content Version",
    id: "ContentVersion",
    width: "15%",
  },
  {
    label: "Game Option",
    id: "GameOptionID",
    width: "15%",
  },
];

const useGameInfoHook = () => {
  const [adminSyncGameData] = useAdminSyncGameDataMutation();
  const [adminGetGameItemVersionData] =
    useAdminGetGameItemVersionDataMutation();

  const [giLoading, setGiLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [rawData, setRawData] = useState([] as gameInfoType[]);
  const [gameInfo, setGameInfo] = useState([] as gameInfoType[]);

  const [searchText, setSearchText] = useState("");
  const [currentGt, setCurrentGt] = useState(-1);
  const [tag, setTag] = useState("-1");

  const [currentAppContentVersion, setCurrentAppContentVersion] = useState({
    appId: 1,
    contentVersionIds: [1],
  } as serverAppContentVersionType);

  const { data: serverAppContentVersion, loading } =
    useGetAllServerAppContentVersionQuery({ context });

  const filterAll = (
    data: gameInfoType[],
    txt: string,
    t: string,
    gt: number,
    p: number
  ) => {
    if (data && data?.length) {
      let dataFiltered = data;
      if (txt) {
        dataFiltered = dataFiltered?.filter((e: any) =>
          e.gameName.toLowerCase().includes(txt.toLowerCase())
        );
      }
      if (t != "-1") {
        dataFiltered = dataFiltered?.filter((e: any) => e?.tags?.includes(t));
      }
      if (!isNaN(+gt) && +gt != -1) {
        dataFiltered = dataFiltered?.filter((e: any) => e.gameTypeId == +gt);
      }
      dataFiltered = dataFiltered?.map((e: any) => {
        const cvn =
          serverAppContentVersion?.getAllServerAppContentVersion?.data?.find(
            (asac: any) => asac.contentVersionId == e.contentVersionId
          );
        return {
          id: e.id,
          contentVersionId: e.contentVersionId,
          contentVersionName: cvn ? cvn?.description : "",
          gameName: e.gameName,
          displayTime: e.displayTime,
          fileConfig: e.fileConfig,
          customConfig: e.customConfig,
          gameOption: e.gameOption,
          thumbnail: e.thumbnail,
          thumbnailURL: e.thumbnailURL,
          difficulty: e.difficulty,
          tags: e.tags,
          gameDuration: e.gameDuration,
          allowUser: e.allowUser,
          restrictMachineType: e.restrictMachineType,
          status: e.status,
          gameTypeId: e.gameTypeId,
          categoryId: e.categoryId,
          open: false,
          textEdit: false,
          edited: false,
        };
      });

      const dataGi = dataFiltered.slice(p * 20, (p + 1) * 20);
      setGameInfo(dataGi);
      const ttPage = Math.ceil(dataFiltered.length / ROWS_PER_PAGE);
      setTotalPage(ttPage);
    } else {
      toast.error("Hmmm!!! Something went wrong, please refresh page!", {
        autoClose: 3000,
      });
    }
  };

  const refetch = async (appContent: serverAppContentVersionType) => {
    setGiLoading(true);
    const { data: gameItemData } = await adminGetGameItemVersionData({
      variables: {
        input: {
          versionIds: appContent.contentVersionIds,
        },
      },
    });
    const data = gameItemData?.adminGetGameItemVersionData?.data || [];
    setRawData(data);
    filterAll(data, searchText, tag, currentGt, currentPage);
    setGiLoading(false);
  };

  const { data: dataGT } = useGetAllGameTypesDataQuery({
    variables: {
      input: {
        appId: Number(currentAppContentVersion?.appId || 1),
      },
    },
  });

  const [gameTypes, setGameTypes] = useState([] as gameTypesType[]);

  const [appContentVersions, setAppContentVersions] = useState(
    [] as serverAppContentVersionType[]
  );

  useMemo(() => {
    if (
      serverAppContentVersion &&
      serverAppContentVersion.getAllServerAppContentVersion?.data?.length
    ) {
      const groupData: any = {};
      serverAppContentVersion.getAllServerAppContentVersion?.data?.map(
        (currentValue: any) => {
          if (!groupData[currentValue.appId]) {
            groupData[currentValue.appId] = {
              serverVersionId: currentValue.serverVersionId,
              appId: currentValue.appId,
              contentVersionIds: [currentValue.contentVersionId],
              versionNumbers: [currentValue.versionNumber],
              appName: currentValue.appName,
              description: currentValue.description,
              appGroupId: currentValue.appGroupId,
            };
          } else {
            groupData[currentValue.appId].contentVersionIds.push(
              currentValue.contentVersionId
            );
            groupData[currentValue.appId].versionNumbers.push(
              currentValue.versionNumber
            );
          }
        },
        {}
      );

      setCurrentAppContentVersion(groupData[1]);
      setAppContentVersions(groupData);
      refetch(groupData[1]);
    }
  }, [serverAppContentVersion]);

  useMemo(() => {
    if (dataGT && dataGT.getAllGameTypesData?.data?.length) {
      setGameTypes(
        dataGT.getAllGameTypesData?.data
          .sort((a: any, b: any) => a.id - b.id)
          .map((e: any) => {
            return {
              ID: e.id,
              Name: e.name,
            };
          })
      );
    }
  }, [dataGT]);
  const onChangeApp = (event: any) => {
    const { value } = event.target;

    setCurrentAppContentVersion(appContentVersions[+value]);
    setCurrentPage(0);
    setTag("-1");
    setSearchText("");
    refetch(appContentVersions[+value]);
  };

  const handleSearch = (textInput: string) => {
    setSearchText(textInput);
    filterAll(rawData, textInput, tag, currentGt, currentPage);
  };

  const filterType = (event: any) => {
    const { value } = event.target;
    setCurrentGt(+value);
    filterAll(rawData, searchText, tag, +value, currentPage);
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
    filterAll(rawData, searchText, tag, currentGt, page);
  };

  // const onRequestSync = async () => {
  //   await adminSyncGameData({
  //     variables: {
  //       input: {
  //         appId: currentAppContentVersion?.appId || 1,
  //       },
  //     },
  //   })
  //     .then((res) => {
  //       toast.success("Sync Game Info Success", { autoClose: 3000 });
  //     })
  //     .catch((err) => {
  //       //   errorHandle(err, formik)
  //       toast.error("Sync Game Info Error", { autoClose: 3000 });
  //     });
  // };

  const filterTag = (event: any) => {
    const { value } = event.target;
    setTag(value);
    filterAll(rawData, searchText, value, currentGt, currentPage);
  };

  return {
    loading: loading || giLoading,
    gameInfo,
    columns,
    appContentVersions,
    currentAppContentVersion,
    gameTypes,
    onChangeApp,
    handleSearch,
    handleChangePage,
    currentPage,
    totalPage,
    filterType,
    currentGt,
    tag,
    filterTag,
    // onRequestSync,
  };
};

export default useGameInfoHook;

import {
  useGetAllServerAppContentVersionQuery,
  // useAdminAddContentVersionDataMutation,
  // useAdminDeleteContentVersionDataMutation,
  useAdminRefreshGameInfoDataMutation,
  useAdminGetSyncGameConfigDataMutation,
  useAdminSubmitSyncGameConfigDataMutation,
  useSharedQuery,
} from "services/graphql/";
import { useState, useMemo } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const context = {};

type contentVersionType = {
  id: string;
  serverVersionId: number;
  appId: number;
  contentVersionId: string;
  appName: string;
  description: string;
  appGroupId: number;
  priority: number;
  open: boolean;
  edited: boolean;
};

type contentVersionObj = {
  appId: number;
  appName: string;
  data: contentVersionType[];
};

const useGameContentVersionsHook = () => {
  // const [deleteContentVersion] = useAdminDeleteContentVersionDataMutation();
  // const [addContentVersion] = useAdminAddContentVersionDataMutation();
  const [getSyncGameConfigData] = useAdminGetSyncGameConfigDataMutation();
  const [submitSyncGameConfigData] = useAdminSubmitSyncGameConfigDataMutation();
  const [refreshGameInfoData] = useAdminRefreshGameInfoDataMutation();

  const [processing, setProcessing] = useState(false);
  const { data, loading, refetch } = useGetAllServerAppContentVersionQuery({
    context,
    fetchPolicy: "no-cache",
  });
  const [contentVersions, setContentVersions] = useState(
    [] as contentVersionObj[]
  );
  useMemo(() => {
    if (data && data.getAllServerAppContentVersion?.data?.length) {
      const allData = data.getAllServerAppContentVersion?.data;
      const dataCT = [] as contentVersionObj[];
      for (let i = 0; i < allData?.length; i++) {
        const elData = allData[i];
        const fIdx = dataCT.findIndex(
          (xItem: contentVersionObj) => xItem.appId == elData.appId
        );
        elData.id = elData.contentVersionId;
        if (fIdx == -1) {
          dataCT.push({
            appId: elData.appId,
            appName: elData.appName,
            data: [elData],
          } as contentVersionObj);
        } else {
          dataCT[fIdx].data.push(elData);
        }
      }
      setContentVersions(dataCT);
    }
  }, [data]);

  const [currentUser, setCurrentUser] = useState({
    username: "",
    email: "",
  });

  const { data: dataCurrentUser } = useSharedQuery();
  useMemo(() => {
    if (dataCurrentUser && dataCurrentUser.currentUser) {
      setCurrentUser({
        username: dataCurrentUser.currentUser?.username,
        email: (dataCurrentUser.currentUser?.emails?.edges || [])[0]?.node
          ?.email,
      });
    }
  }, [dataCurrentUser]);

  const [openModal, setOpenModal] = useState(false);
  const [dataChange, setDataChange] = useState({
    gameItems: [],
    gameCategories: [],
    gameTypes: [],
    gameOptions: [],
  });

  const [dataApply, setDataApply] = useState({
    gameItems: [],
    gameCategories: [],
    gameTypes: [],
    gameOptions: [],
  });

  const [requestApp, setRequestApp] = useState(0);

  const addNewContentVersions = (el: contentVersionObj) => {
    toast.error("Contact VN BE team to support !!!");
    // const cloneCV = [...contentVersions]
    // const fIdx = cloneCV.findIndex((xItem: contentVersionObj) => xItem.appId == el.appId)
    // if (fIdx != -1) {
    //   const newPriority = Math.max(...cloneCV[fIdx].data.map(e => e.priority)) + 1
    //   const newContentVersion: contentVersionType = {
    //     id: "new-id-" + Math.random(),
    //     serverVersionId: el.data[0].serverVersionId,
    //     appId: el.appId,
    //     contentVersionId: "",
    //     appName: el.appName,
    //     description: "",
    //     appGroupId: el.data[0].appGroupId,
    //     priority: newPriority,
    //     open: el.data[0].open,
    //     edited: true,
    //   }
    //   cloneCV[fIdx].data.push(newContentVersion)
    //   setContentVersions(cloneCV)
    //   return newContentVersion;
    // }
  };

  const onSaveContent = async (cti: contentVersionType) => {
    toast.error("Stop support !!!");
    // if (!cti.description || !cti.contentVersionId) {
    //   return;
    // }

    // setProcessing(true);
    // try {
    //   const { data, errors } = await addContentVersion({
    //     variables: {
    //       input: {
    //         id: cti.contentVersionId,
    //         description: cti.description,
    //         status: "PUBLISHED",
    //         appId: cti.appId,
    //         priority: cti.priority,
    //       },
    //     },
    //   });

    //   const cloneCV = [...contentVersions];
    //   const fIdx = cloneCV.findIndex(
    //     (xItem: contentVersionObj) => xItem.appId == cti.appId
    //   );
    //   if (fIdx != -1) {
    //     const dataIdx = cloneCV[fIdx];
    //     const dIdx = dataIdx.data.findIndex((el: any) => el.id == cti.id);
    //     cloneCV[fIdx].data[dIdx].edited = false;
    //     setContentVersions(cloneCV);
    //   }

    //   toast.success("Success !!!");
    // } catch (e) {
    //   toast.error("ERROR !!!");
    // } finally {
    //   refetch();
    // }
    // setProcessing(false);
  };

  const onDeleteContent = async (item: contentVersionType) => {
    toast.error("Stop support !!!");
    // const result = await Swal.fire({
    //   title: "Are you sure?",
    //   text: "This action cannot be undone!",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Yes!",
    // });
    // if (!result.isConfirmed) {
    //   return;
    // }
    // if (!item.contentVersionId) {
    //   return;
    // }
    // try {
    //   setProcessing(true);
    //   await deleteContentVersion({
    //     variables: {
    //       input: {
    //         id: item.contentVersionId,
    //       },
    //     },
    //   });
    //   toast.success("Success !!!");
    //   const cloneCV = [...contentVersions];
    //   const fIdx = cloneCV.findIndex(
    //     (xItem: contentVersionObj) => xItem.appId == item.appId
    //   );
    //   if (fIdx != -1) {
    //     const dataIdx = cloneCV[fIdx];
    //     cloneCV[fIdx].data = dataIdx.data.filter((el: any) => el.id != item.id);
    //     setContentVersions(cloneCV);
    //   }
    // } catch (error) {
    //   toast.error("ERROR !!!");
    // } finally {
    //   setProcessing(false);
    // }
  };

  const onChangeDes = (ev: any, item: contentVersionType) => {
    const { value } = ev.target;

    const cloneCV = [...contentVersions];
    const fIdx = cloneCV.findIndex(
      (xItem: contentVersionObj) => xItem.appId == item.appId
    );
    if (fIdx != -1) {
      const iIdx = cloneCV[fIdx].data.findIndex(
        (xItem: contentVersionType) =>
          xItem.appId == item.appId && xItem.id == item.id
      );
      if (fIdx != -1) {
        cloneCV[fIdx].data[iIdx].description = value;
      }
      setContentVersions(cloneCV);
    }
  };

  const onChangeId = (ev: any, item: contentVersionType) => {
    const { value } = ev.target;

    const cloneCV = [...contentVersions];
    const fIdx = cloneCV.findIndex(
      (xItem: contentVersionObj) => xItem.appId == item.appId
    );
    if (fIdx != -1) {
      const iIdx = cloneCV[fIdx].data.findIndex(
        (xItem: contentVersionType) =>
          xItem.appId == item.appId && xItem.id == item.id
      );
      if (fIdx != -1) {
        cloneCV[fIdx].data[iIdx].contentVersionId = value;
      }
      setContentVersions(cloneCV);
    }
  };

  const onRefreshGameInfoData = async (appId: any) => {
    if (!currentUser.username || !currentUser.email) {
      toast.error("Refresh page !!!");
      return;
    }

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });
    if (!result.isConfirmed) {
      return;
    }
    try {
      setProcessing(true);
      await refreshGameInfoData({
        context,
        variables: {
          input: {
            appId: +appId,
            username: currentUser.username,
            email: currentUser.email,
          },
        },
      });
      toast.success("Success ! Try in client !");
    } catch (err) {
      toast.error("ERROR !!!");
    } finally {
      setProcessing(false);
    }
  };

  const onGetDataChange = async (appId: any) => {
    if (!currentUser.username || !currentUser.email) {
      toast.error("Refresh page !!!");
      return;
    }

    try {
      setProcessing(true);
      const res = await getSyncGameConfigData({
        context,
        variables: {
          input: {
            appId: +appId,
            username: currentUser.username,
            email: currentUser.email,
          },
        },
      });
      if (res.data?.adminGetSyncGameConfigData) {
        const { gameItems, gameCategories, gameTypes, gameOptions } =
          res.data?.adminGetSyncGameConfigData;
        setDataChange({
          gameItems: gameItems || [],
          gameCategories: gameCategories || [],
          gameTypes: gameTypes || [],
          gameOptions: gameOptions || [],
        });
        setRequestApp(+appId);
      }
      setOpenModal(true);
    } catch (err) {
      toast.error("ERROR !!!");
    } finally {
      setProcessing(false);
    }
  };

  const onRequestSync = async () => {
    if (!currentUser.username || !currentUser.email || !requestApp) {
      toast.error("Refresh page !!!");
      return;
    }

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "This action cannot be undone!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!",
    });
    if (!result.isConfirmed) {
      return;
    }

    try {
      setProcessing(true);
      const res = await submitSyncGameConfigData({
        context,
        variables: {
          input: {
            appId: +requestApp,
            username: currentUser.username,
            email: currentUser.email,
            syncData: dataApply,
          },
        },
      });
      console.log(dataApply);
      await refetch();
    } catch (err) {
      toast.error("ERROR !!!");
    } finally {
      setProcessing(false);
      setRequestApp(0);
    }
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const onSelectItem = (type: string, item: any) => {
    const cloneData = Object.assign({}, dataApply);
    if (!Object.hasOwn(cloneData, type)) {
      // @ts-ignore
      cloneData[type] = [];
    }
    // @ts-ignore
    cloneData[type].push(item);
    setDataApply(cloneData);
  };

  return {
    loading: loading || processing,
    contentVersions,
    addNewContentVersions,
    onSaveContent,
    onDeleteContent,
    onChangeDes,
    onChangeId,
    onRefreshGameInfoData,
    dataChange,
    openModal,
    onGetDataChange,
    onRequestSync,
    handleClose,
    onSelectItem,
    dataApply,
  };
};

export default useGameContentVersionsHook;

import { useHistory } from "react-router-dom";
import {
  useAdminAddGameItemVersionDataMutation,
  useGetAllGameInfoDataQuery,
  useGetAllServerAppContentVersionQuery,
  useGetAllGameOptionsDataQuery,
} from "services/graphql/";
import { useState, useMemo } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { formSchema } from "./formSchemal";
import { CONSTANTS } from "consts";
import { errorHandle } from "utils";

const context = {};
type gameTypesType = {
  id: number;
  name: string;
};

type serverAppContentVersionType = {
  serverVersionId: number;
  appId: number;
  contentVersionIds: number[];
  versionNumbers: string[];
  appName: string;
  description: string;
  appGroupId: number;
};

const useGameInfoHook = () => {
  const history = useHistory();
  const [addGameItemVersion] = useAdminAddGameItemVersionDataMutation();

  const formik = useFormik({
    initialValues: {
      id: "Game_",
      contentVersionId: "",
      gameName: "",
      shortDescription: "",
      longDescription: "",
      displayTime: "",
      fileConfig: "",
      customConfig: {},
      gameOption: "",
      thumbnail: "",
      difficulty: {},
      tags: [],
      gameDuration: "",
      allowUser: [],
      restrictMachineType: "",
      status: "",
      gameTypeId: "",
      categoryId: "",
    },
    validationSchema: formSchema,
    onSubmit: (values) => {},
  });

  const [loading, setLoading] = useState(false);
  const [gameTypes, setGameTypes] = useState([] as gameTypesType[]);

  const [allcategoriesVersions, setAllcategoriesVersions] = useState(
    [] as any[]
  );
  const [availableCategoriesVersions, setAvailableCategoriesVersions] =
    useState([] as any[]);

  const [allContentVersions, setAllContentVersions] = useState([] as any[]);
  const { data: serverAppContentVersion, loading: loadingSAC } =
    useGetAllServerAppContentVersionQuery({ context, fetchPolicy: "no-cache" });
  const [availableContentVersions, setAvailableContentVersions] = useState(
    [] as any[]
  );

  const [availableApps, setAvailableApps] = useState([] as any[]);

  const [apps, setApps] = useState([] as any[]);
  const [currentApp, setCurrentApp] = useState(1);

  const {
    data: giData,
    loading: giLoading,
    refetch: giRefetch,
  } = useGetAllGameInfoDataQuery({
    context,
    variables: {
      input: {
        appId: currentApp,
        ignoreData: ["ListGameInfoData", "ListGameOption"],
      },
    },
    fetchPolicy: "no-cache",
  });

  useMemo(() => {
    if (giData && giData.getAllGameInfoData?.GameList) {
      const gameList = giData.getAllGameInfoData?.GameList;

      setGameTypes(gameList.ListGameType);
      setAvailableCategoriesVersions(gameList.ListCategoryItemData);
    }
  }, [giData]);

  const { data: dataGO } = useGetAllGameOptionsDataQuery({
    context,
    fetchPolicy: "no-cache",
  });
  const [gameOptions, setGameOptions] = useState([] as gameTypesType[]);

  useMemo(() => {
    if (dataGO && dataGO.getAllGameOptionsData?.data?.length) {
      setGameOptions(
        dataGO.getAllGameOptionsData?.data.map((e: any) => {
          return {
            id: e.id,
            name: e.id,
          };
        })
      );
    }
  }, [dataGO]);

  useMemo(() => {
    if (serverAppContentVersion?.getAllServerAppContentVersion?.data?.length) {
      const data = serverAppContentVersion?.getAllServerAppContentVersion?.data;
      const allApps = {} as any;
      const listSacv = data.map((e: any) => {
        allApps[e.appId] = {
          Name: e.appName,
          ID: e.appId,
        };
        return {
          id: e.contentVersionId,
          name: e.description,
          appName: e.appName,
          appId: e.appId,
          priority: e.priority,
        };
      });
      setApps(Object.values(allApps));
      setAllContentVersions(listSacv);

      const availCVs = listSacv.filter((sac: any) => sac.appId == currentApp);
      setAvailableContentVersions(availCVs);

      const defaultContentVersionId = (listSacv[0] || {}).id || "";
      formik.setFieldValue("contentVersionId", defaultContentVersionId);

      const availableApps = listSacv.filter(
        (e: any) => e.id == defaultContentVersionId
      );
      setAvailableApps(availableApps);
    }
  }, [serverAppContentVersion]);

  const onChangeTags = (name: any, value: any) => {
    formik.setFieldValue(
      "tags",
      value.map((e: any) => e.value)
    );
  };

  const onChangeAllowUser = (name: any, value: any) => {
    formik.setFieldValue(
      "allowUser",
      value.map((e: any) => e.value)
    );
  };

  const onChangeGameType = (ev: any) => {
    const { value } = ev.target;
    formik.setFieldValue("gameTypeId", +value);
  };

  const onChangeCustomConfig = (jsObject: any) => {
    if (jsObject) {
      formik.setFieldValue("customConfig", jsObject, true);
    }
  };

  const onChangeDifficulty = (jsObject: any) => {
    if (jsObject) {
      formik.setFieldValue("difficulty", jsObject, true);
    }
  };

  const onChangeGameOption = (ev: any) => {
    const { value } = ev.target;
    formik.setFieldValue("gameOption", value);
  };

  const onChangeGameStatus = (ev: any) => {
    const { value } = ev.target;
    formik.setFieldValue("status", value);
  };

  const onChangeContentVersion = (ev: any) => {
    const { value } = ev.target;
    formik.setFieldValue("contentVersionId", value);

    const availableApps = allContentVersions.filter((e: any) => e.id == value);
    setAvailableApps(availableApps);
  };

  const onChangeGameCategory = (ev: any) => {
    const { value } = ev.target;
    formik.setFieldValue("categoryId", value);
  };

  const onChangeApp = (event: any) => {
    const { value } = event.target;
    setCurrentApp(+value);

    const availCVs = allContentVersions.filter(
      (sac: any) => sac.appId == +value
    );
    setAvailableContentVersions(availCVs);

    const defaultContentVersionId = (availCVs[0] || {}).id || "";
    formik.setFieldValue("contentVersionId", defaultContentVersionId);

    const availableApps = allContentVersions.filter(
      (e: any) => e.id == defaultContentVersionId
    );
    setAvailableApps(availableApps);

    giRefetch({
      input: {
        appId: +value,
        ignoreData: ["ListGameInfoData", "ListGameOption"],
      },
    });
  };

  const handleSubmitForms = async () => {
    setLoading(true);
    if (formik.values.gameName.trim() !== "") {
      if (formik.isValid) {
        setLoading(true);
        addGameItemVersion({
          variables: {
            input: {
              id: formik.values.id,
              contentVersionId: formik.values.contentVersionId,
              gameName: formik.values.gameName.toUpperCase(),
              shortDescription: formik.values.shortDescription,
              longDescription: formik.values.longDescription,
              displayTime: formik.values.displayTime,
              fileConfig: formik.values.fileConfig,
              customConfig: formik.values.customConfig,
              gameOption: formik.values.gameOption,
              thumbnail: formik.values.thumbnail,
              difficulty: formik.values.difficulty,
              tags: formik.values.tags,
              gameDuration: +formik.values.gameDuration,
              allowUser: formik.values.allowUser?.join(","),
              restrictMachineType: formik.values.restrictMachineType,
              status: formik.values.status,
              gameTypeId: formik.values.gameTypeId,
              categoryId: formik.values.categoryId,
              action: "create",
            },
          },
        })
          .then((res) => {
            setLoading(false);
            toast.success("Add Game Item Success", { autoClose: 3000 });
            history.push(CONSTANTS.NAVIGATION_URL.GAME_INFO_V2_GAME_INFO);
          })
          .catch((err) => {
            errorHandle(err, formik);
            setLoading(false);
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (!formik.isValid) {
        setLoading(false);
        const el = document.getElementById("profileFormId");
        el?.scrollIntoView();
        return;
      } else {
        setLoading(false);
        alert("have error");
      }
    } else {
      setLoading(false);
    }
  };

  return {
    loading: loading || loadingSAC || giLoading,
    formik,
    gameTypes,
    gameOptions,
    availableApps,
    allContentVersions,
    availableCategoriesVersions,
    apps,
    currentApp,
    availableContentVersions,
    onChangeApp,
    onChangeTags,
    onChangeAllowUser,
    handleSubmitForms,
    onChangeGameType,
    onChangeCustomConfig,
    onChangeDifficulty,
    onChangeGameOption,
    onChangeGameStatus,
    onChangeContentVersion,
    onChangeGameCategory,
  };
};

export default useGameInfoHook;
